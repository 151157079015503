
import Vue from 'vue';
import CustomerInfo from '@/components/CustomerInfoScreen/CustomerInfo.vue';
import CustomerAddress from '@/components/CustomerInfoScreen/CustomerAddress.vue';
import ReviewScreen from '@/components/ReviewScreen/ReviewScreen.vue';
import SuccessScreen from '@/components/UI/SuccessScreen.vue';
import AppointmentBookerDatePicker from '@/components/AppointmentBooker/AppointmentBookerDatePicker.vue';
import AppointmentBookerTimePicker from '@/components/AppointmentBooker/AppointmentBookerTimePicker.vue';
import BottomNavigation from '@/components/NavBar/BottomNavigation.vue';
import ExtraInfos from '@/components/AppointmentBooker/ExtraInfo.vue';

export default Vue.extend({
  name: 'AppointmentBooker',
  data: () => ({
    today: new Date(),
    dates: true,
    times: [],
  }),
  components: {
    CustomerInfo,
    CustomerAddress,
    ReviewScreen,
    SuccessScreen,
    AppointmentBookerDatePicker,
    AppointmentBookerTimePicker,
    BottomNavigation,
    ExtraInfos,
  },
  computed: {
    isViewing: {
      get(): boolean {
        return this.$store.getters.getAppointmentIsViewing;
      },
      set(value: boolean) {
        this.$store.commit('setAppointmentIsViewing', value);
      },
    },
    showNextButton: {
      get(): boolean {
        return this.currentStep > 1 && this.currentStep < this.totalSteps;
      },
    },
    buttonText: {
      get(): string {
        if (this.currentStep === (this.totalSteps - 1)) {
          return 'Daten Senden';
        }
        return 'Nächster Schritt';
      },
    },
    showSnackbar: {
      get(): boolean {
        return this.$store.getters.getUtilsShowSnackbar;
      },
      set(value: boolean) {
        this.$store.commit('setUtilsShowSnackbar', value);
      },
    },
    snackbarMessage: {
      get(): string {
        return this.$store.getters.getUtilsSnackbarMessage;
      },
      set(value: string) {
        this.$store.commit('setUtilsSnackbarMessage', value);
      },
    },
    isCustomerInfoEmpty: {
      get(): boolean {
        return !this.$store.getters.isUserInfoComplete;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    currentStep: {
      get(): number {
        return this.$store.getters.getAppointmentCurrentStep;
      },
      set(value: number) {
        this.$store.commit('setAppointmentCurrentStep', value);
      },
    },
    totalSteps: {
      get(): number {
        return this.$store.getters.getAppointmentTotalsteps;
      },
      set(value: number) {
        this.$store.commit('setAppointmentTotalsteps', value);
      },
    },
    appointment: {
      get(): string {
        if (this.isViewing) {
          return this.$store.getters.getViewingAppointment;
        }
        return this.$store.getters.getRepairAppointment;
      },
    },
    appointmentDateObject: {
      get(): Date {
        return new Date(Date.parse(this.appointment));
      },
    },
    requireCustomer: {
      get(): boolean {
        if (this.$route.params.token !== undefined) {
          return this.$store.getters.getAuftragRequireCustomerInfo;
        }
        return true;
      },
    },
    request: {
      get(): boolean {
        return this.$route.params.token === undefined;
      },
    },
    stepOffset: {
      get(): number {
        return this.request ? 0 : 1;
      },
    },
  },
  methods: {
    setClientInfo(value: any) {
      this.$store.dispatch('setUserInfos', value);
    },
    setDate(date: any) {
      this.times = date.slots;
      if (this.isViewing) {
        this.$store.commit('setViewingAppointmentDate', date.date);
      } else {
        this.$store.commit('setRepairAppointmentDate', date.date);
      }
      this.incrementStep();
    },
    setAppointment(appointment: string) {
      if (this.isViewing) {
        this.$store.commit('setViewingAppointment', appointment);
      } else {
        this.$store.commit('setRepairAppointment', appointment);
      }
      this.incrementStep();
    },
    incrementStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep += 1;
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchOrgInfo', this.$route.params.orgString);
    this.$store.dispatch('appointmentNavBar');
  },
  created() {
    this.currentStep = 0;
    if (this.requireCustomer) {
      this.totalSteps = 5 + (1 - this.stepOffset);
    } else {
      this.totalSteps = 3 + (1 - this.stepOffset);
    }
  },
  beforeMount() {
    if (this.request) {
      return;
    }
    const payload = {
      orgString: this.$route.params.orgString,
      token: this.$route.params.token,
    };
    this.$store.dispatch('fetchAuftragInfo', payload);
  },
});
