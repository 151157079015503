import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { UtilsState } from './types';

const tmp = 0;

const actions: ActionTree<UtilsState, RootState> = {
  utilsHandleAjaxError({ commit, dispatch }, error: any) {
    commit('setUtilsCriticalErrorType', 'http');
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log('1');
      // // console.log(error.response.data);
      // // console.log(error.response.status);
      // // console.log(error.response.headers);
      // // console.log(error);
      // handle Verification
      dispatch('utilsHandleResponseCodes', error.response.status);

      commit('setUtilsCriticalErrorMessage', error.response.data);
      commit('setUtilsResponseStatusCode', error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log('2');
      if (error.message === 'Network Error') {
        commit('setUtilsConnectionLost', true);
      } else {
        commit('setUtilsCriticalErrorMessage', error.message);
        commit('setUtilsResponseStatusCode', 0);
        commit('setUtilsCriticalError', true);
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      // // console.log('Error', error.message);
      // console.log('3');
      commit('setUtilsNonCriticalErrorMessage', error.message);
      commit('setUtilsResponseStatusCode', -1);
      commit('setUtilsNonCriticalError', true);
      // // console.log(error.stack);
    }
    commit('setUtilsLoading', false);
    // // console.log(error.config);
    // // console.log(error.message);
  },
  utilsHandleResponseCodes({ commit, dispatch }, statusCode: number) {
    // // console.log('status code', statusCode);
    if (statusCode === 428) {
      commit('setAuftragRequireVerification', true);
    } else if (statusCode === 409) {
      dispatch('restartAppointmentBooking');
    } else {
      commit('setUtilsCriticalError', true);
    }
  },
  utilsHandleSuccussfullVerification({ commit }) {
    commit('setAuftragRequireVerification', false);
    commit('setUtilsCriticalError', false);
  },
};

export {
  actions,
  tmp,
};
