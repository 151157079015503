
import Vue from 'vue';

export default Vue.extend({
  name: 'CustomerAddress',
  data: () => ({
    valid: true,
    houseNrRules: [
      (v: string) => /[0-9]+[a-z]*/i.test(v) || 'Hausnummer muss gültig sein',
    ],
    zipRules: [
      (v: string) => /^[0-9]{5}$/.test(v) || 'Postleitzahl muss gültig sein',
    ],
    snackbar: false,
    userTitles: [
      'Herr', 'Frau', 'Firma',
    ],
  }),
  computed: {
    street: {
      get(): string {
        return this.$store.getters.getUserStreet;
      },
      set(value: string) {
        this.$store.commit('setUserStreet', value);
      },
    },
    houseNr: {
      get(): string {
        return this.$store.getters.getUserHouseNumber;
      },
      set(value: string) {
        this.$store.commit('setUserHouseNumber', value);
      },
    },
    extraAddressLine: {
      get(): string {
        return this.$store.getters.getUserExtraAddressLine;
      },
      set(value: string) {
        this.$store.commit('setUserExtraAddressLine', value);
      },
    },
    zip: {
      get(): string {
        return this.$store.getters.getUserZip;
      },
      set(value: string) {
        this.$store.commit('setUserZip', value);
      },
    },
    city: {
      get(): string {
        return this.$store.getters.getUserCity;
      },
      set(value: string) {
        this.$store.commit('setUserCity', value);
      },
    },
    country: {
      get(): string {
        return this.$store.getters.getUserCountry;
      },
      set(value: string) {
        this.$store.commit('setUserCountry', value);
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
  },
  methods: {
    nextStep() {
      this.$emit('next');
      // this.snackbar = true;
    },
    setStreet(value: string) {
      this.street = value;
    },
    setHouseNr(value: string) {
      this.houseNr = value;
    },
    setExtraAddressLine(value: string) {
      this.extraAddressLine = value;
    },
    setZip(value: string) {
      this.zip = value;
    },
    setCity(value: string) {
      this.city = value;
    },
    setCountry(value: string) {
      this.country = value;
    },
  },
});
