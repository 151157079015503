
import Vue from 'vue';
import TheMenu from '@/components/UI/TheMenu.vue';

export default Vue.extend({
  name: 'NavBar',
  props: {
    condition: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    overlay: false,
  }),
  computed: {
    // showBackButton: {
    //   get(): boolean {
    //     return this.$store.getters.getShowBackButton;
    //   },
    // },
    logo: {
      get(): string {
        return this.$store.getters.getOrgLogo;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    secondaryColor: {
      get(): string {
        return this.$store.getters.getOrgSecondaryColor;
      },
    },
    logoClasses: {
      get(): string {
        return this.showBackButton ? 'logo logo-moved' : 'logo';
      },
    },
    currentStep: {
      get(): number {
        return this.$store.getters.getStepperCurrentStep;
      },
      set(value: number) {
        this.$store.commit('setStepperCurrentStep', value);
      },
    },
    totalSteps: {
      get(): number {
        return this.$store.getters.getStepperTotalSteps;
      },
    },
  },
  methods: {
    showMenu() {
      this.overlay = true;
    },
    hideMenu() {
      this.overlay = false;
    },
    launchReport() {
      this.$emit('launchReport');
      this.hideMenu();
    },
    launchViewingAppointment() {
      this.$emit('launchViewingAppointment');
      this.hideMenu();
    },
    launchRepairAppointment() {
      this.$emit('launchRepairAppointment');
      this.hideMenu();
    },
    homePage() {
      // console.log(this.$route.params);
      if (this.$route.params.orgString) {
        if (this.$route.params.token) {
          // console.log('token');
          this.$router.push(`/${this.$route.params.orgString}/${this.$route.params.token}`)
            .catch(() => (
              this.$router.go(0)
            ));
        } else {
          this.$router.push(`/${this.$route.params.orgString}`)
            .catch(() => (
              this.$router.go(0)
            ));
        }
      } else {
        this.$router.push('/')
          .catch(() => (
            this.$router.go(0)
          ));
      }
    },
    decrementStep() {
      if (this.$route.params.orgString) {
        if (this.currentStep === 0) {
          this.homePage();
          return;
        }
        this.currentStep -= 1;
      }
    },
  },
  components: {
    TheMenu,
  },
});
