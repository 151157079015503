import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/utils/getters';
import { mutations } from '@/store/utils/mutations';
import { actions } from '@/store/utils/actions';
import { UtilsState } from '@/store/utils/types';

const state: UtilsState = {
  checkVehicleDrivable: true,
  loading: false,
  loadingAvailability: false,
  absoluteLoading: false,
  criticalError: false,
  nonCriticalError: false,
  imageUploadLoading: false,
  docSignUploadLoading: false,
  stepperCurrentStep: 0,
  stepperTotalSteps: -1,
  showSnackbar: false,
  connectionLost: false,
  snackbarMessage: '',
};

export const tmp = 0;

export const utils: Module<UtilsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
