
import Vue from 'vue';
import ReviewScreenCustomer from '@/components/ReviewScreen/ReviewScreenCustomer.vue';
import ReviewScreenVehicle from '@/components/ReviewScreen/ReviewScreenVehicle.vue';
import ReviewScreenAppointment from '@/components/ReviewScreen/ReviewScreenAppointment.vue';

export default Vue.extend({
  name: 'ReviewScreen',
  props: {
    customer: {
      type: Boolean,
      default: true,
    },
    report: {
      type: Boolean,
      default: true,
    },
    appointment: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ReviewScreenCustomer,
    ReviewScreenVehicle,
    ReviewScreenAppointment,
  },
  computed: {
    renderCustomer: {
      get(): boolean {
        if (this.$route.params.token !== undefined) {
          return this.$store.getters.getAuftragRequireCustomerInfo && this.customer;
        }
        return this.customer;
      },
    },
    isViewing: {
      get(): boolean {
        return this.$store.getters.getViewingAppointment;
      },
    },
    appointmentTitle: {
      get(): string {
        if (this.isViewing) {
          return 'Besichtigungstermin';
        }
        return 'Reparaturtermin';
      },
    },
  },
  methods: {
    nextStep() {
      this.$emit('next');
    },
  },
});
