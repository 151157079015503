
import Vue from 'vue';

export const phoneRules = [
  (v: string) => !!v || 'Erforderlich',
  (v: string) => /^(\+|00)/.test(v) || 'Bitte Ländervorwahl mitgeben',
  (v: string) => /^\+?[0-9]+$/.test(v) || 'Telefonnummer muss gültig sein',
  (v: string) => (v && v.length >= 8) || 'Telefonnummer muss mehr als 8 Ziffern haben',
  (v: string) => /(\(?([\d \-)–+/(]+){6,}\)?([ .\-–/]?)([\d]+))/.test(v)
    || 'Telefonnummer muss gültig sein',
  (v: string) => !/^(.)\1*$/.test(v) || 'Telefonnummer muss gültig sein',
];

export const emailRules = [
  (v: string) => /.+@.+\..+/.test(v) || 'Email muss gültig sein',
];

export default Vue.extend({
  name: 'CustomerInfo',
  data: () => ({
    valid: true,
    phoneRules,
    emailRules,
    snackbar: false,
    userTitles: ['Herr', 'Frau', 'Firma'],
  }),
  computed: {
    title: {
      get(): string {
        return this.$store.getters.getUserTitle;
      },
      set(value: string) {
        this.$store.commit('setUserTitle', value);
      },
    },
    name: {
      get(): string {
        return this.$store.getters.getUserName;
      },
      set(value: string) {
        this.$store.commit('setUserName', value);
      },
    },
    phone: {
      get(): string {
        return this.$store.getters.getUserPhone;
      },
      set(value: string) {
        this.$store.commit('setUserPhone', value);
      },
    },
    email: {
      get(): string {
        return this.$store.getters.getUserEmail;
      },
      set(value: string) {
        this.$store.commit('setUserEmail', value);
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
  },
  methods: {
    nextStep() {
      if (this.phone !== '') {
        this.$emit('next');
      } else {
        this.snackbar = true;
      }
    },
    setTitle(value: string) {
      this.title = value;
    },
    setName(value: string) {
      this.name = value;
    },
    setPhone(value: string) {
      this.phone = value;
    },
    setEmail(value: string) {
      this.email = value;
    },
  },
});
