
import Vue from 'vue';
import ProgressBar from '@/components/ReportStepper/ProgressBar.vue';
import ReportStepperWindow from '@/components/ReportStepper/ReportStepperWindow.vue';
import BottomNavigation from '@/components/NavBar/BottomNavigation.vue';
import { otherNumberPlatePattern, germanNumberPlatePattern } from '@/components/ReportStepper/NumberPlate.vue';

export default Vue.extend({
  name: 'ReportStepper',
  props: {
    request: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ProgressBar,
    ReportStepperWindow,
    BottomNavigation,
  },
  computed: {
    showNextButton: {
      get(): boolean {
        return this.currentStep < this.totalSteps;
      },
    },
    totalSteps: {
      get(): number {
        return this.$store.getters.getDamageReportTotalSteps;
      },
      set(value: number) {
        this.$store.commit('setStepperTotalSteps', value);
      },
    },
    buttonText: {
      get(): string {
        if (this.currentStep === this.totalSteps - 1) {
          return 'Daten Senden';
        }
        return 'Nächster Schritt';
      },
    },
    error: {
      get(): boolean {
        return this.$store.getters.getUtilsCriticalError;
      },
    },
    showSnackbar: {
      get(): boolean {
        return this.$store.getters.getUtilsShowSnackbar;
      },
      set(value: boolean) {
        this.$store.commit('setUtilsShowSnackbar', value);
      },
    },
    snackbarMessage: {
      get(): string {
        return this.$store.getters.getUtilsSnackbarMessage;
      },
      set(value: string) {
        this.$store.commit('setUtilsSnackbarMessage', value);
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    currentStep: {
      get(): number {
        return this.$store.getters.getDamageReportCurrentStep;
      },
      set(value: number) {
        this.$store.commit('setDamageReportCurrentStep', value);
      },
    },
    vehicleKz: {
      get(): string {
        return this.$store.getters.getDamageReportVehicleKz;
      },
      set(value: string) {
        this.$store.commit('setDamageReportVehicleKz', value);
      },
    },
    isCustomerInfoEmpty: {
      get(): boolean {
        return !this.$store.getters.isUserInfoComplete;
      },
    },
    requireCustomer: {
      get(): boolean {
        if (this.$route.params.token !== undefined) {
          return this.$store.getters.getAuftragRequireCustomerInfo;
        }
        return true;
      },
    },
    kzCountry: {
      get(): 'germany' | 'other' {
        return this.$store.getters.getDamageReportVehicleKzCountry;
      },
    },
  },
  methods: {
    postData() {
      this.$store.dispatch('sendDamageReportIsFinished', {
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
      });
    },
    nextStep() {
      console.log('nextStep', this.currentStep);
      switch (this.currentStep) {
        case 0: {
          // KZ
          if (!this.request) {
            this.incrementStep();
            return;
          }
          let pattern;
          if (this.kzCountry === 'other') {
            pattern = otherNumberPlatePattern;
          } else {
            pattern = germanNumberPlatePattern;
          }
          console.log(this.vehicleKz);

          if (this.vehicleKz !== '' && pattern.test(this.vehicleKz)) {
            this.incrementStep();
          } else {
            this.snackbarMessage = 'Bitte geben Sie ein gültiges Kennzeichen ein!';
            this.showSnackbar = true;
          }
          return;
        }
        case 8: {
          if (this.isCustomerInfoEmpty && this.requireCustomer) {
            this.snackbarMessage = 'Bitte geben Sie Ihre Kontaktinformationen ein!';
            this.showSnackbar = true;
          } else {
            this.incrementStep();
          }
          return;
        }
        case 9: {
          if (this.requireCustomer) {
            this.$store.dispatch('sendUserInfo', {
              orgString: this.$route.params.orgString,
              token: this.$route.params.token,
            });
            this.incrementStep();
          } else {
            this.postData();
          }
          return;
        }
        case this.totalSteps: {
          this.postData();
          return;
        }
        default: {
          this.incrementStep();
          break;
        }
      }
    },
    incrementStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep += 1;
      }
      if (this.currentStep === this.totalSteps) {
        this.$store.commit('setShowBackButton', false);
        this.postData();
      }
    },
    decrementStep() {
      if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },
  },
  created() {
    this.$store.dispatch('initDamageReportStepper');
    const stepOffset = this.request ? 0 : 1;
    if (this.requireCustomer) {
      this.$store.commit('setDamageReportTotalSteps', 11 - stepOffset);
    } else {
      this.$store.commit('setDamageReportTotalSteps', 9 - stepOffset);
    }
  },
});
