import { render, staticRenderFns } from "./ChatMessageBox.vue?vue&type=template&id=bdcb6600&scoped=true&"
import script from "./ChatMessageBox.vue?vue&type=script&lang=ts&"
export * from "./ChatMessageBox.vue?vue&type=script&lang=ts&"
import style0 from "./ChatMessageBox.vue?vue&type=style&index=0&id=bdcb6600&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdcb6600",
  null
  
)

export default component.exports