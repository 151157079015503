import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { AppointmentSlotsState } from './types';

export const tmp = 0;

export const getters: GetterTree<AppointmentSlotsState, RootState> = {
  getViewingAppointment(state): string {
    return state.viewingAppointment;
  },
  getRepairAppointment(state): string {
    return state.repairAppointment;
  },
  getAppointmentIsViewing(state): boolean {
    return state.isViewing;
  },
  getAppointmentsFirstWeek(state): any {
    return state.firstWeek;
  },
  getAppointmentsSecondWeek(state): any {
    return state.secondWeek;
  },
  getAppointmentsThirdWeek(state): any {
    return state.thirdWeek;
  },
  getAppointmentsFourthWeek(state): any {
    return state.fourthWeek;
  },
  getAppointmentsWeek: (state) => (index: number) => {
    switch (index) {
      case 1:
        return state.secondWeek;
      case 2:
        return state.thirdWeek;
      case 3:
        return state.fourthWeek;
      default:
        return state.firstWeek;
    }
  },
  getAppointmentsWeekLoading: (state) => (index: number) => {
    switch (index) {
      case 1:
        return state.secondWeekLoading;
      case 2:
        return state.thirdWeekLoading;
      case 3:
        return state.fourthWeekLoading;
      default:
        return state.firstWeekLoading;
    }
  },
  getAppointmentTotalsteps(state): number {
    return state.totalSteps;
  },
  getAppointmentCurrentStep(state): number {
    return state.currentStep;
  },
};
