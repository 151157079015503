
import Vue from 'vue';
import { germanNumberPlatePattern, otherNumberPlatePattern } from '@/components/ReportStepper/NumberPlate.vue';

export default Vue.extend({
  name: 'ExtraInfos',
  data: () => ({
    valid: true,
    snackbar: false,
  }),
  props: {
    request: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    insurance: {
      get(): string {
        return this.$store.getters.getDamageReportInsurance;
      },
      set(value: string) {
        this.$store.commit('setDamageReportInsurance', value);
      },
    },
    damageNumber: {
      get(): string {
        return this.$store.getters.getDamageReportNumber;
      },
      set(value: string) {
        this.$store.commit('setDamageReportNumber', value);
      },
    },
    notes: {
      get(): string {
        return this.$store.getters.getDamageReportNotes;
      },
      set(value: string) {
        this.$store.commit('setDamageReportNotes', value);
      },
    },
    kzCountry: {
      get(): 'germany' | 'other' {
        return this.$store.getters.getDamageReportVehicleKzCountry;
      },
      set(value: 'germany' | 'other'): void {
        this.$store.commit('setDamageReportVehicleKzCountry', value);
      },
    },
    vehicleKz: {
      get(): string {
        return this.$store.getters.getDamageReportVehicleKz;
      },
      set(value: string): void {
        this.$store.commit('setDamageReportVehicleKz', value);
      },
    },
    vehicleKzRules: {
      get() {
        return [(value: string) => {
          if (this.kzCountry === 'other') {
            return (otherNumberPlatePattern.test(value)) || 'Ungültig Kennzeichen';
          }
          return (germanNumberPlatePattern.test(value)) || 'Ungültig Kennzeichen';
        }];
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
  },
  methods: {
    setNotes(value: string) {
      this.notes = value;
    },
    setInsurance(value: string) {
      this.insurance = value;
    },
    setDamageNumber(value: string) {
      this.damageNumber = value;
    },
    setKz(value: string) {
      this.vehicleKz = value;
    },
  },
});
