
import Vue from 'vue';

export default Vue.extend({
  name: 'ExampleImage',
  data: () => ({
    overlay: false,
  }),
  methods: {
    showImg() {
      this.overlay = true;
    },
    hideImg() {
      this.overlay = false;
    },
  },
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
  },
  props: {
    imgSrc: {
      type: String,
      required: true,
      default: 'milage.jpg',
    },
    allowOverlay: {
      type: Boolean,
      default: true,
    },
  },
});
