
import Vue from 'vue';
import ReportStepper from '@/components/ReportStepper/ReportStepper.vue';

export default Vue.extend({
  name: 'DamageReport',
  components: {
    ReportStepper,
  },
  computed: {
    orgString: {
      get(): string {
        return this.$route.params.orgString;
      },
    },
    token: {
      get(): string {
        return this.$route.params.token;
      },
    },
    request: {
      get(): boolean {
        return this.$route.params.token === undefined;
      },
    },
    orgAcceptsRequests: {
      get(): boolean {
        return this.$store.getters.getOrgAcceptsRequests;
      },
    },
    makeDamageReport: {
      get(): boolean {
        return this.$store.getters.getAuftragMakeReport;
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchOrgInfo', this.$route.params.orgString);
    this.$store.dispatch('damageReportNavBar');
  },
  created() {
    if (this.request && !this.orgAcceptsRequests) {
      this.$router.push(`/${this.orgString}`);
      return;
    }
    if (!this.request && !this.makeDamageReport) {
      this.$router.push(`/${this.orgString}/${this.token}`);
    }
    this.$store.commit('setDamageReportCurrentStep', 0);
  },
  beforeMount() {
    if (this.request) {
      return;
    }
    const payload = {
      orgString: this.$route.params.orgString,
      token: this.$route.params.token,
    };
    this.$store.dispatch('fetchAuftragInfo', payload);
  },
});
