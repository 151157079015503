
import Vue from 'vue';

export default Vue.extend({
  name: 'ServiceCard',
  props: {
    buttonText: {
      type: String,
      default: 'Daten hochladen',
    },
    imgSrc: {
      type: String,
      default:
        'damgedCar.svg',
    },
    title: {
      type: String,
      default: 'Schadeninformation übermitteln',
    },
    description: {
      type: String,
      default:
        'Übermitteln Sie schnell und unkompliziert die Schadensdaten an uns.',
    },
  },
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    secondaryColor: {
      get(): string {
        return this.$store.getters.getOrgSecondaryColor;
      },
    },
  },
  methods: {
    launch() {
      this.$emit('update');
      this.$store.commit('setShowBackButton', true);
    },
  },
});
