
import Vue from 'vue';
import ConnectionLostScreen from '@/components/ErrorHandler/ConnectionLostScreen.vue';
import ErrorScreen from '@/components/ErrorHandler/ErrorScreen.vue';

export default Vue.extend({
  name: 'ErrorHandler',
  components: {
    ConnectionLostScreen,
    ErrorScreen,
  },
  computed: {
    absoluteLoading: {
      get(): boolean {
        return this.$store.getters.getUtilsAbsoluteLoading;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.getters.getUtilsLoading;
      },
    },
    error: {
      get(): boolean {
        return this.$store.getters.getUtilsCriticalError;
      },
    },
    errorMessage: {
      get(): string {
        return this.$store.getters.getUtilsCriticalErrorMessage;
      },
    },
    statusCode: {
      get(): number {
        return this.$store.getters.getUtilsResponseStatusCode;
      },
    },
    connectionLost: {
      get(): boolean {
        // return this.error && this.statusCode === -1;
        return this.$store.getters.getUtilsConnectionLost;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
  },
});
