import { MutationTree } from 'vuex';
import { NavBarState } from '@/store/navbar/types';

export const tmp = 0;

export const mutations: MutationTree<NavBarState> = {
  setNavBarShowBackButton(state, value: boolean) {
    state.showBackButton = value;
  },
  setNavBarShowMenu(state, value: boolean) {
    state.showMenu = value;
  },
};
