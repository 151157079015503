
import Vue from 'vue';
import WeeklyCalendar from '@/components/AppointmentBooker/WeeklyCalendar.vue';

export default Vue.extend({
  name: 'AppointmentBookerDatePicker',
  components: {
    WeeklyCalendar,
  },
  computed: {
    appointment: {
      get(): Date {
        if (this.isViewing) {
          return this.$store.getters.getViewingAppointment;
        }
        return this.$store.getters.getRepairAppointment;
      },
    },
    isViewing: {
      get(): boolean {
        return this.$store.getters.getAppointmentIsViewing;
      },
    },
  },
  methods: {
    notify(date: string) {
      this.$emit('notify', date);
    },
  },
});
