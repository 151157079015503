import { MutationTree } from 'vuex';
import { AuftragState } from '@/store/auftrag/types';

export const tmp = 0;

export const mutations: MutationTree<AuftragState> = {
  initAuftrag(state) {
    state.isComplete = false;
    state.token = '';
    state.completionDate = undefined;
    state.currentRepairStep = undefined;
    state.steps = [];
  },
  setAuftragToken(state, value: string) {
    state.token = value;
  },
  setAuftragIsComplete(state, value: boolean) {
    state.isComplete = value;
  },
  setAuftragCompletionDate(state, value: string | Date) {
    if (value !== undefined) {
      if (typeof value === 'string') {
        state.completionDate = new Date(value);
        return;
      }
      state.completionDate = value;
    }
  },
  setAuftragCompletionTime(state, value: string | Date) {
    if (value !== undefined) {
      if (typeof value === 'string') {
        state.completionTime = new Date(value);
        return;
      }
      state.completionTime = value;
    }
  },
  setAuftragCurrentRepairStep(state, value: number) {
    if (value !== undefined) {
      state.currentRepairStep = value;
    }
  },
  setAuftragSteps(state, value: Array<any>) {
    if (value !== undefined) {
      state.steps = value;
    }
  },
  setAuftragShowRepairStatus(state, value: boolean) {
    state.showRepairStatus = value;
  },
  setAuftragSignDocs(state, value: boolean) {
    state.signDocs = value;
  },
  setAuftragMakeReport(state, value: boolean) {
    state.makeReport = value;
  },
  setAuftragMakeViewingAppointment(state, value: boolean) {
    state.makeViewingAppointment = value;
  },
  setAuftragMakeRepairAppointment(state, value: boolean) {
    state.makeRepairAppointment = value;
  },
  setAuftragAccessToken(state, value: string) {
    state.accessToken = value;
  },
  setAuftragRequireVerification(state, value: boolean) {
    state.requireVerification = value;
  },
  setAuftragRequireCustomerInfo(state, value: boolean) {
    state.requireCustomerInfo = value;
  },
};
