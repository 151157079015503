
import Vue from 'vue';

export default Vue.extend({
  name: 'ChatMessageBox',
  data: () => ({
    showTime: false,
  }),
  props: {
    message: {
      type: Object,
      default: ({
        message: 'test Message',
        sender: 'customer',
        sendDate: '04.03.2022',
      }) as Record<string, string>,
    },
    messageId: {
      type: String,
      required: true,
    },
    // showDivider: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  components: {},
  computed: {
    showDivider: {
      get(): boolean {
        return this.$store.getters.getChatLastMessageRead === this.messageId;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    secondaryColor: {
      get(): string {
        return this.$store.getters.getOrgSecondaryColor;
      },
    },
    sendTime: {
      get(): string {
        const date = new Date(this.message.timestamp);
        return date.toLocaleString();
      },
    },
  },
  methods: {
    boxBackgroundColor() {
      return this.message.sender === 'customer'
        ? this.LightenDarkenColor(this.secondaryColor, 60)
        : this.LightenDarkenColor(this.primaryColor, 60);
    },
    LightenDarkenColor(colorCode: string, amount: number) {
      let color = colorCode;
      let usePound = false;
      if (color[0] === '#') {
        color = color.slice(1);
        usePound = true;
      }

      const num = parseInt(color, 16);
      let r = (num >> 16) + amount; // eslint-disable-line no-bitwise
      if (r > 255) {
        r = 255;
      } else if (r < 0) {
        r = 0;
      }

      let b = ((num >> 8) & 0x00FF) + amount; // eslint-disable-line no-bitwise
      if (b > 255) {
        b = 255;
      } else if (b < 0) {
        b = 0;
      }

      let g = (num & 0x0000FF) + amount; // eslint-disable-line no-bitwise
      if (g > 255) {
        g = 255;
      } else if (g < 0) {
        g = 0;
      }
      // eslint-disable-line no-bitwise
      // // console.log((usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16));
      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16); // eslint-disable-line no-bitwise
    },
    toggleTime() {
      this.showTime = !this.showTime;
    },
  },
  mounted() {
    if (this.message.sender === 'org' && this.message.seen === false) {
      this.$store.dispatch('markMessageAsRead', this.messageId);
    }
  },
});
