import axios from 'axios';
import { ActionTree } from 'vuex';
import { HOSTNAME } from '../shared';
import { RootState } from '../types';
import { OrgService, OrgServicesState } from './types';

const tmp = 0;

const actions: ActionTree<OrgServicesState, RootState> = {
  fetchOrgServices({ commit }, orgString: string): void {
    const url = `${HOSTNAME}/enduser/org/${orgString}/services`;
    // Get Organisation Info
    commit('setOrgServicesLoading', true);
    axios.get(url)
      .then((response) => {
        const services: OrgService[] = response.data.services.map((obj: any) => <OrgService> {
          title: obj.title,
          description: obj.description,
          price: obj.price,
          reference: obj.reference,
          image: obj.image,
        });
        commit('setOrgBookableServices', services);
        commit('setOrgServicesLoading', false);
      })
      .catch((error) => {
        // // console.log(`error: ${error}`);
        commit('setOrgServicesError', true);
        commit('setOrgServicesLoading', false);
      });
  },
  showServices({ commit }): void {
    commit('setOrdServiceShowMenu', true);
    commit('setLaunchAppointment', false);
    commit('setLaunchreport', false);
    commit('setShowBackButton', false);
  },
  launchViewingAppointment({ commit }): void {
    commit('setStepperCurrentStep', 0);
    commit('setAppointmentIsViewing', true);
    commit('setOrdServiceShowMenu', false);
    commit('setOrgServiceLaunchReport', false);
    commit('setOrgServiceLaunchRepairAppointment', false);
    commit('setOrgServiceLaunchViewingAppointment', true);
    commit('setShowBackButton', true);
  },
  launchRepairAppointment({ commit }): void {
    commit('setStepperCurrentStep', 0);
    commit('setAppointmentIsViewing', false);
    commit('setOrdServiceShowMenu', false);
    commit('setOrgServiceLaunchReport', false);
    commit('setOrgServiceLaunchViewingAppointment', false);
    commit('setOrgServiceLaunchRepairAppointment', true);
    commit('setShowBackButton', true);
  },
  launchReportStepper({ commit }): void {
    // console.log('launch report stepper');
    commit('setStepperCurrentStep', 0);
    commit('setOrdServiceShowMenu', false);
    commit('setOrgServiceLaunchViewingAppointment', false);
    commit('setOrgServiceLaunchRepairAppointment', false);
    commit('setOrgServiceLaunchReport', true);
    commit('setShowBackButton', true);
  },
};

export {
  actions,
  tmp,
};
