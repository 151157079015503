
import Vue from 'vue';
import PdrStamp from '@/components/TheFooter/PdrStamp.vue';

export default Vue.extend({
  name: 'TheFooter',
  components: {
    PdrStamp,
  },
  props: {
    toggle: {
      type: Boolean,
      default: true,
    },
  },
});
