
import Vue from 'vue';

// const pattern = /^[a-zäöü]{1,3}(\s|-)*[a-zäöü]{1,2}(\s|-)*[0-9]{1,4}(e)?$/i;
// const pattern = /^[a-zäöü]{1,3}(\s|-)*[a-zäöü0-9\s]{2,7}$/i;
export const germanNumberPlatePattern = /^[A-ZÖÜÄ]{1,3}(\s|-)?[A-ZÖÜÄ]{1,2}(\s|-)?[1-9]\d{1,3}E?$/i;
export const otherNumberPlatePattern = /^[\w\s\-.#_/\\]{3,20}$/i;

export default Vue.extend({
  name: 'NumberPlate',
  data: () => ({

  }),
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    vehicleKz: {
      get(): string {
        return this.$store.getters.getDamageReportVehicleKz;
      },
      set(value: string): void {
        this.$store.commit('setDamageReportVehicleKz', value);
      },
    },
    kzCountry: {
      get(): 'germany' | 'other' {
        return this.$store.getters.getDamageReportVehicleKzCountry;
      },
      set(value: 'germany' | 'other'): void {
        this.$store.commit('setDamageReportVehicleKzCountry', value);
      },
    },
    vehicleKzRules: {
      get() {
        return [(value: string) => {
          if (this.kzCountry === 'other') {
            return (otherNumberPlatePattern.test(value)) || 'Ungültig Kennzeichen';
          }
          return (germanNumberPlatePattern.test(value)) || 'Ungültig Kennzeichen';
        }];
      },
    },
  },
});
