import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/damageReport/getters';
import { actions } from '@/store/damageReport/actions';
import { mutations } from '@/store/damageReport/mutations';
import { DamageReportState } from './types';

export const state: DamageReportState = {
  vehicleKz: '',
  kzCountry: 'germany',
  insurance: '',
  damageNumber: '',
  notes: '',
  vehicleRegistration: {
    front: {},
    back: {},
  },
  vehicleDashboard: {},
  farImages: {
    'front-left': {},
    'back-right': {},
  },
  nearImages: {},
  extraImages: {},
  overviewImage: {},
  isFinished: false,
  currentStep: 0,
  totalSteps: -1,
};

export const tmp = 0;

export const damageReport: Module<DamageReportState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
