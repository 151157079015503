import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { AuftragState, RepairStep } from './types';

export const tmp = 0;

export const getters: GetterTree<AuftragState, RootState> = {
  getAuftragToken(state): string {
    return state.token;
  },
  getAuftragCompletionDate(state): Date | undefined {
    return state.completionDate;
  },
  getAuftragCompletionTime(state): Date | undefined {
    return state.completionTime;
  },
  getAuftragCurrentRepairStep(state): number | undefined {
    return state.currentRepairStep;
  },
  getAuftragSteps(state): RepairStep[] | undefined {
    return state.steps;
  },
  getAuftragIsComplete(state): boolean {
    return state.isComplete;
  },
  getAuftragShowRepairStatus(state): boolean {
    return state.showRepairStatus;
  },
  getAuftragSignDocs(state): boolean {
    return state.signDocs;
  },
  getAuftragMakeReport(state): boolean {
    return state.makeReport;
  },
  getAuftragMakeViewingAppointment(state): boolean {
    return state.makeViewingAppointment;
  },
  getAuftragMakeRepairAppointment(state): boolean {
    return state.makeRepairAppointment;
  },
  getAuftragAccessToken(state): string {
    return state.accessToken;
  },
  getAuftragRequireVerification(state): boolean {
    return state.requireVerification;
  },
  getAuftragRequireCustomerInfo(state): boolean {
    return state.requireCustomerInfo;
  },
};
