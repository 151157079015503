import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import { auth } from './firebase';

Vue.config.productionTip = false;

let app: any;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});

// // Subscribe to state changes
// store.subscribe((mutation, state) => {
//   if (state.storeLocaly === true) {
//     localStorage.setItem('store', window.btoa(JSON.stringify(state)));
//   }
// });
