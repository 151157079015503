
import Vue from 'vue';
import ServiceCard from '@/components/UI/ServiceCard.vue';

export default Vue.extend({
  name: 'OrgHomePage',
  components: {
    ServiceCard,
  },
  data: () => ({
    overlay: false,
  }),
  computed: {
    orgAcceptsRequests: {
      get(): boolean {
        return this.$store.getters.getOrgAcceptsRequests;
      },
    },
    orgAcceptsAppointments: {
      get(): boolean {
        return this.$store.getters.getOrgAcceptsAppointments;
      },
    },
    name: {
      get(): string {
        return this.$store.getters.getOrgName;
      },
    },
    orgString: {
      get(): string {
        return this.$route.params.orgString;
      },
    },
  },
  methods: {
    goToDamageReport() {
      this.$router.push(`/${this.orgString}/schadenaufnahme`);
    },
    goToViewingAppointment() {
      this.$router.push(`/${this.orgString}/besichtigung`);
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchOrgInfo', this.$route.params.orgString);
    this.$store.dispatch('orgPageNavBar');
  },
  created() {
    this.$store.dispatch('showServices');
  },
});
