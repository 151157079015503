import { MutationTree } from 'vuex';
import { OrgService, OrgServicesState } from './types';

export const tmp = 0;

export const mutations: MutationTree<OrgServicesState> = {
  setOrgServiceAcceptsRequests(state, value: boolean) {
    state.acceptsRequests = value;
  },
  setOrgServiceAcceptsAppointments(state, value: boolean) {
    state.acceptsAppointments = value;
  },
  setOrgServicesLoading(state, value: boolean) {
    state.orgServicesLoading = value;
  },
  setOrgServicesError(state, value: boolean) {
    state.orgServicesError = value;
  },
  setOrgBookableServices(state, value: OrgService[]) {
    state.bookableServices = value;
  },
  setOrgServiceLaunchViewingAppointment(state, value: boolean) {
    state.launchViewingAppointment = value;
  },
  setOrgServiceLaunchRepairAppointment(state, value: boolean) {
    state.launchRepairAppointment = value;
  },
  setOrgServiceLaunchReport(state, value: boolean) {
    state.launchReport = value;
  },
  setOrdServiceShowMenu(state, value: boolean) {
    state.showMenu = value;
  },
};
