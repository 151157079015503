import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/services/getters';
import { mutations } from '@/store/services/mutations';
import { actions } from '@/store/services/actions';
import { OrgServicesState } from '@/store/services/types';

const state: OrgServicesState = {
  acceptsRequests: false,
  acceptsAppointments: false,
  orgServicesLoading: false,
  orgServicesError: false,
  bookableServices: [],
  showMenu: true,
  launchReport: false,
  launchViewingAppointment: false,
  launchRepairAppointment: false,
};

export const tmp = 0;

export const organisationServices: Module<OrgServicesState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
