import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { DocumentState, SignDocument } from './types';

export const tmp = 0;

export const getters: GetterTree<DocumentState, RootState> = {
  getSignDocuments(state): SignDocument[] {
    return state.documents;
  },
  getSignDocument(state, index: number): SignDocument {
    return state.documents[index];
  },
  getDocumentsCurrentStep(state): number {
    return state.currentStep;
  },
  getDocumentsTotalSteps(state): number {
    return state.totalSteps;
  },
};
