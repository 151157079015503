import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/user/getters';
import { mutations } from '@/store/user/mutations';
import { actions } from '@/store/user/actions';
import { UserState } from '@/store/user/types';

const state: UserState = {
  uid: '',
  recaptchaToken: '',
  authorized: false,
  title: '',
  name: '',
  phone: '',
  email: '',
  street: '',
  houseNumber: '',
  extraAddressLine: '',
  zip: '',
  city: '',
  country: '',
};

export const tmp = 0;

export const user: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
