import axios, { AxiosRequestConfig } from 'axios';
import { ActionTree } from 'vuex';
import { germanNumberPlatePattern, otherNumberPlatePattern } from '@/components/ReportStepper/NumberPlate.vue';
import {
  configAxios, getMonday, getUrl, HOSTNAME, getRecaptchaToken,
} from '@/store/shared';
import { RootState } from '../types';
import { AppointmentSlotsState } from './types';

export const tmp = 0;

export const actions: ActionTree<AppointmentSlotsState, RootState> = {
  async bookViewingAppointment({ commit, dispatch, state }, payload): Promise<void> {
    commit('setUtilsLoading', true);

    const config: AxiosRequestConfig = await configAxios();
    // console.log('sending Auftrag Request');
    const url = getUrl(payload);

    // recaptcha token
    const token = await getRecaptchaToken('viewing_appointment');

    const auftrag = {
      viewingAppointment: state.viewingAppointment,
      isFinished: true,
      recaptchaToken: token,
    };
    axios.post(url, auftrag, config)
      .then((response) => {
        // console.log(response);
        commit('setUtilsLoading', false);
      })
      .catch((error) => {
        // console.log(error);
        dispatch('utilsHandleAjaxError', error);
      });
  },
  async bookRepairAppointment({ commit, dispatch, state }, payload): Promise<void> {
    commit('setUtilsLoading', true);

    const config: AxiosRequestConfig = await configAxios();
    // console.log('sending Auftrag Request');
    const url = getUrl(payload);

    // recaptcha token
    const token = await getRecaptchaToken('repair_appointment');
    const auftrag = {
      repairAppointment: state.repairAppointment,
      isFinished: true,
      recaptchaToken: token,
    };
    axios.post(url, auftrag, config)
      .then((response) => {
        // console.log(response);
        commit('setUtilsLoading', false);
      })
      .catch((error) => {
        // console.log(error);
        dispatch('utilsHandleAjaxError', error);
      });
  },
  async fetchWeekAvailability(
    { commit, dispatch, getters },
    index: number,
  ): Promise<void> {
    if (index === 0) {
      commit('setUtilsLoading', true);
    }
    const url = `${HOSTNAME}/enduser/org/${getters.getOrgString}/appointment?day=${getMonday(index)}`;
    const config: AxiosRequestConfig = await configAxios();

    // Fetch Organisation Slots
    commit('setAppointmentsWeekLoading', { index, value: true });
    axios.get(url, config)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          commit('setAppointmentsWeek', {
            index,
            value: response.data,
          });
        } else {
          commit('setAppointmentsWeek', {
            index,
            value: 'empty',
          });
          // console.log('no slots available');
        }
        commit('setAppointmentsWeekLoading', { index, value: false });
        if (index === 0) {
          commit('setUtilsLoading', false);
        }
      })
      .catch((error) => {
        dispatch('utilsHandleAjaxError', error);
        commit('setAppointmentsWeekLoading', { index, value: false });
      });
  },
  restartAppointmentBooking({ commit, dispatch }): void {
    commit('initAppointmentState');
    dispatch('fetchWeekAvailability', 0);
    dispatch('fetchWeekAvailability', 1);
    dispatch('fetchWeekAvailability', 2);
    dispatch('fetchWeekAvailability', 3);
    commit('setAppointmentCurrentStep', 0);
    commit('setUtilsSnackbarMessage', 'Termin nicht verfügbar');
    commit('setUtilsShowSnackbar', true);
  },
  incrementAppointmentCurrentStep({ getters, commit, dispatch }, request: boolean) {
    const currentStep = getters.getAppointmentCurrentStep;
    const totalSteps = getters.getAppointmentTotalsteps;
    switch (currentStep) {
      case 2: {
        // if appointment requires no info this is the last step
        if (totalSteps === 3) {
          commit('setNavBarShowBackButton', false);
          if (getters.getAppointmentIsViewing) {
            dispatch('bookViewingAppointment', {
              orgString: getters.getOrgString,
              token: getters.getAuftragToken,
            });
          } else {
            dispatch('bookRepairAppointment', {
              orgString: getters.getOrgString,
              token: getters.getAuftragToken,
            });
          }
          commit('setAppointmentCurrentStep', currentStep + 1);
          return;
        }
        const kennzeichen = getters.getDamageReportVehicleKz;
        const kzCountry = getters.getDamageReportVehicleKzCountry;

        let pattern;
        if (kzCountry === 'other') {
          pattern = otherNumberPlatePattern;
        } else {
          pattern = germanNumberPlatePattern;
        }

        if (kennzeichen && pattern.test(kennzeichen)) {
          commit('setAppointmentCurrentStep', currentStep + 1);
        } else {
          commit('setUtilsSnackbarMessage', 'Bitte geben Sie ein Kennzeichen ein!');
          commit('setUtilsShowSnackbar', true);
        }
        return;
      }
      case totalSteps - 3: {
        const requireCustomer = request || getters.getAuftragRequireCustomerInfo;
        if (!getters.isUserInfoComplete && requireCustomer) {
          commit('setUtilsSnackbarMessage', 'Bitte geben Sie Ihre Kontaktinformationen ein!');
          commit('setUtilsShowSnackbar', true);
        } else {
          commit('setAppointmentCurrentStep', currentStep + 1);
        }
        return;
      }
      case totalSteps - 2: {
        const requireCustomer = request || getters.getAuftragRequireCustomerInfo;
        if (requireCustomer) {
          dispatch('sendUserInfo', {
            orgString: getters.getOrgString,
            token: getters.getAuftragToken,
          });
        }
        commit('setAppointmentCurrentStep', currentStep + 1);
        return;
      }
      case totalSteps - 1: {
        commit('setNavBarShowBackButton', false);
        if (getters.getAppointmentIsViewing) {
          dispatch('bookViewingAppointment', {
            orgString: getters.getOrgString,
            token: getters.getAuftragToken,
          });
        } else {
          dispatch('bookRepairAppointment', {
            orgString: getters.getOrgString,
            token: getters.getAuftragToken,
          });
        }
        commit('setAppointmentCurrentStep', currentStep + 1);
        return;
      }
      default: {
        commit('setAppointmentCurrentStep', currentStep + 1);
        break;
      }
    }
  },
};
