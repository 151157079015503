import { GetterTree } from 'vuex';
import { emailRules, phoneRules } from '@/components/CustomerInfoScreen/CustomerInfo.vue';
import { RootState } from '../types';
import { UserState } from './types';

export const tmp = 0;

export const getters: GetterTree<UserState, RootState> = {
  getUserUid(state): string {
    return state.uid;
  },
  isUserInfoEmpty(state): boolean {
    if (state.name === undefined || state.phone === undefined || state.email === undefined) {
      return true;
    }
    if (state.name.length === 0 || state.phone.length === 0 || state.email.length === 0) {
      return true;
    }
    return false;
  },
  isUserInfoComplete(state): boolean {
    if (state.name === undefined || state.phone === undefined || state.email === undefined) {
      return false;
    }
    if (state.name.length === 0 || state.phone.length === 0 || state.email.length === 0) {
      return false;
    }

    let isComplete = true;
    phoneRules.forEach((rule) => {
      if (typeof rule(state.phone) === 'string') {
        isComplete = false;
      }
    });
    emailRules.forEach((rule) => {
      if (typeof rule(state.email) === 'string') {
        isComplete = false;
      }
    });

    return isComplete;
  },
  getUserAuthorized(state): boolean {
    return state.authorized;
  },
  getUserTitle(state): string {
    return state.title;
  },
  getUserName(state): string {
    return state.name;
  },
  getUserPhone(state): string {
    return state.phone;
  },
  getUserEmail(state): string {
    return state.email;
  },
  getUserStreet(state): string {
    return state.street;
  },
  getUserHouseNumber(state): string {
    return state.houseNumber;
  },
  getUserExtraAddressLine(state): string {
    return state.extraAddressLine;
  },
  getUserZip(state): string {
    return state.zip;
  },
  getUserCity(state): string {
    return state.city;
  },
  getUserCountry(state): string {
    return state.country;
  },
};
