
import Vue from 'vue';

export default Vue.extend({
  name: 'ChatTextBox',
  data: () => ({
    msg: '',
  }),
  components: {},
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
  },
  methods: {
    send() {
      // console.log(`sending msg: ${this.msg}`);
      if (this.msg !== '') {
        this.$store.dispatch('sendChatMessage', this.msg);
        this.msg = '';
      }
    },
  },
});
