import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { ChatMessage, ChatState } from './types';

export const tmp = 0;

export const getters: GetterTree<ChatState, RootState> = {
  getChatroomId(state): string {
    return state.chatroomId;
  },
  getEnableChat(state): boolean {
    return state.enableChat;
  },
  getLaunchChat(state): boolean {
    return state.launchChat;
  },
  getChatMessages(state): Record<string, ChatMessage> {
    return state.messages;
  },
  getChatUnreadMessagesIds(state): string[] {
    return state.unreadMessagesIds;
  },
  getChatLastMessageRead(state): string {
    return state.lastMessageRead;
  },
};
