
import Vue from 'vue';
import NumberPlate from '@/components/ReportStepper/NumberPlate.vue';
import OverviewImageUploader from '@/components/ReportStepper/OverviewImageUploader.vue';
import VehicleRegistrationUploader from '@/components/ReportStepper/VehicleRegistrationUploader.vue';
import VehicleDashboardUploader from '@/components/ReportStepper/VehicleDashboardUploader.vue';
import FarImagesUploader from '@/components/ReportStepper/FarImagesUploader.vue';
import NearImagesUploader from '@/components/ReportStepper/NearImagesUploader.vue';
import ExtraImagesUploader from '@/components/ReportStepper/ExtraImagesUploader.vue';
import CustomerInfo from '@/components/CustomerInfoScreen/CustomerInfo.vue';
import CustomerAddress from '@/components/CustomerInfoScreen/CustomerAddress.vue';
import SuccessScreen from '@/components/UI/SuccessScreen.vue';
import ReviewScreen from '@/components/ReviewScreen/ReviewScreen.vue';
import ExtraInfos from '@/components/ReportStepper/ExtraInfos.vue';

export default Vue.extend({
  name: 'ReportStepperWindow',
  props: {
    request: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NumberPlate,
    CustomerInfo,
    CustomerAddress,
    ReviewScreen,
    SuccessScreen,
    VehicleRegistrationUploader,
    VehicleDashboardUploader,
    FarImagesUploader,
    NearImagesUploader,
    OverviewImageUploader,
    ExtraImagesUploader,
    ExtraInfos,
  },
  computed: {
    stepOffset: {
      get(): number {
        return this.request ? 0 : 1;
      },
    },
    totalSteps: {
      get(): number {
        return this.$store.getters.getDamageReportTotalSteps;
      },
      set(value: number) {
        this.$store.commit('setDamageReportTotalSteps', value);
      },
    },
    error: {
      get(): boolean {
        return this.$store.getters.getUtilsCriticalError;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    currentStep: {
      get(): number {
        return this.$store.getters.getDamageReportCurrentStep;
      },
      set(value: number) {
        this.$store.commit('setDamageReportCurrentStep', value);
      },
    },
    requireCustomer: {
      get(): boolean {
        if (this.$route.params.token !== undefined) {
          return this.$store.getters.getAuftragRequireCustomerInfo;
        }
        return true;
      },
    },
  },
  methods: {
    postData() {
      this.$store.dispatch('sendDamageReportIsFinished', {
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
      });
    },
    incrementStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep += 1;
      }
      if (this.currentStep === this.totalSteps) {
        this.$store.commit('setShowBackButton', false);
        this.postData();
      }
    },
    decrementStep() {
      if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },
    setClientInfo(value: any) {
      this.$store.dispatch('setUserInfos', value);
    },
    sendClientInfo() {
      this.$store.dispatch('sendUserInfo', {
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
      });
      this.incrementStep();
    },
  },
});
