
import Vue from 'vue';
import ImageUploaderProgressScreen from '@/components/ReportStepper/ImageUploaderProgressScreen.vue';
import ExampleImage from '@/components/UI/ExampleImage.vue';

export default Vue.extend({
  name: 'FarImagesUploader',
  data: () => ({
    overlay: false,
    img: '',
    imgSrcs: [
      'L_ok.png',
      'M_no.png',
      'S_no.png',
    ],
  }),
  components: {
    ImageUploaderProgressScreen,
    ExampleImage,
  },
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    frontSide: {
      get(): Record<string, any> {
        return this.$store.getters.getDamageReportFarImage('front-left');
      },
    },
    frontSideEmpty: {
      get(): boolean {
        return Object.keys(this.frontSide || {}).length === 0;
      },
    },
    backSide: {
      get(): Record<string, any> {
        return this.$store.getters.getDamageReportFarImage('back-right');
      },
    },
    backSideEmpty: {
      get(): boolean {
        return Object.keys(this.backSide || {}).length === 0;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.getters.getUtilsImageUploadLoading;
      },
    },
  },
  methods: {
    launchFileSelector(id: string) {
      const element: HTMLElement = document.getElementById(id) as HTMLElement;
      element.click();
    },
    uploadFile(file: File, id: ('front-left' | 'back-right'), description: string) {
      const payload = {
        file,
        description,
        id,
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        attribute: 'image',
        mutation: 'setDamageReportFarImage',
      };
      this.$store.dispatch('uploadDamageReportImage', payload);
    },
    uploadFrontSide(file: File) {
      this.uploadFile(file, 'front-left', 'Außen Vorne Links');
    },
    uploadBackSide(file: File) {
      this.uploadFile(file, 'back-right', 'Außen Hinten Rechts');
    },
    showImage(image: string) {
      this.img = image;
      this.overlay = true;
    },
    hideImage() {
      this.overlay = false;
    },
    deleteFile(image: Record<string, any>) {
      const payload = {
        filename: image.filename,
        id: image.id,
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        attribute: 'image',
        mutation: 'deleteDamageReportFarImage',
      };
      this.$store.dispatch('deleteDamageReportImage', payload);
    },
  },
});
