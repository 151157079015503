
import Vue from 'vue';
import VerificationScreen from '@/components/VerificationScreen/VerificationScreen.vue';
import ChatPopup from '@/components/ChatScreen/ChatPopup.vue';
import ServiceCard from '@/components/UI/ServiceCard.vue';
import AuftragProgressScreen from '@/components/AuftragPage/AuftragProgressScreen.vue';

export default Vue.extend({
  name: 'AuftragPage',
  components: {
    AuftragProgressScreen,
    VerificationScreen,
    ChatPopup,
    ServiceCard,
  },
  computed: {
    token: {
      get(): string {
        return this.$route.params.token;
      },
    },
    orgString: {
      get(): string {
        return this.$route.params.orgString;
      },
    },
    enableChat: {
      get(): boolean {
        return this.$store.getters.getEnableChat;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.getters.getUtilsLoading;
      },
    },
    criticalError: {
      get(): boolean {
        return this.$store.getters.getUtilsCriticalError;
      },
    },
    showProgress: {
      get(): boolean {
        return this.$store.getters.getAuftragShowRepairStatus;
      },
    },
    renderAuftrag: {
      get(): boolean {
        return !(this.criticalError || this.loading);
      },
    },
    requireVerification: {
      get(): boolean {
        return this.$store.getters.getAuftragRequireVerification;
      },
    },
    makeReport: {
      get(): boolean {
        return this.$store.getters.getAuftragMakeReport;
      },
    },
    makeViewingAppointment: {
      get(): boolean {
        return this.$store.getters.getAuftragMakeViewingAppointment;
      },
    },
    makeRepairAppointment: {
      get(): boolean {
        return this.$store.getters.getAuftragMakeRepairAppointment;
      },
    },
  },
  methods: {
    async fetchAuftrag() {
      const payload = {
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
      };
      await this.$store.dispatch('fetchAuftragInfo', payload);
    },
    goToDamageReport() {
      // console.log('wwwww');
      this.$router.push(`/${this.orgString}/${this.token}/schadenaufnahme`);
    },
    goToViewingAppointment() {
      this.$router.push(`/${this.orgString}/${this.token}/besichtigung`);
    },
    goToRepairAppointment() {
      this.$router.push(`/${this.orgString}/${this.token}/reparatur`);
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchOrgInfo', this.$route.params.orgString);
    const payload = {
      orgString: this.$route.params.orgString,
      token: this.$route.params.token,
    };
    this.$store.dispatch('fetchAuftragInfo', payload);
  },
  mounted() {
    this.$store.dispatch('auftragPageNavBar');
    if (this.$route.query.chat === 'true') {
      // console.log('Launch chat!');
      this.$router.push(`/${this.orgString}/chat`);
    }
  },
  updated() {
    // console.log(this.makeReport, this.makeViewingAppointment, this.makeRepairAppointment);
    if (this.makeReport && !(this.makeViewingAppointment || this.makeRepairAppointment)) {
      this.$router.push(`/${this.orgString}/${this.token}/schadenaufnahme`);
      return;
    }
    if (this.makeViewingAppointment && !(this.makeReport || this.makeRepairAppointment)) {
      this.$router.push(`/${this.orgString}/${this.token}/besichtigung`);
      return;
    }
    if (this.makeRepairAppointment && !(this.makeViewingAppointment || this.makeReport)) {
      this.$router.push(`/${this.orgString}/${this.token}/reparatur`);
    }
  },
});
