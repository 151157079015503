
import Vue from 'vue';

export default Vue.extend({
  name: 'ProgressBar',
  data: () => ({
    steps: [
      'Fahrzeuginformation',
      'Fallbeschreibung',
      'Kontaktinformation',
    ],
    width: '250',
  }),
  props: {
    totalSteps: {
      type: Number,
      default: 7,
    },
    currentStep: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    progressPercentage: {
      get(): number {
        return Math.round((this.currentStep / this.totalSteps) * 100);
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    secondaryColor: {
      get(): string {
        return this.$store.getters.getOrgSecondaryColor;
      },
    },
    stepColors: {
      get():Array<string> {
        return [this.isActive(0), this.isActive(1), this.isActive(2)];
      },
    },
  },
  methods: {
    progressWidth(): string {
      let firstIcon = 0;
      let lastIcon = 0;
      firstIcon = document.getElementById('icon1')!.getBoundingClientRect().left;
      lastIcon = document.getElementById('icon3')!.getBoundingClientRect().left;
      return `${lastIcon - firstIcon}`;
    },
    handleResize(): void {
      this.width = this.progressWidth();
    },
    isActive(index: number) {
      switch (index) {
        case 0:
          return this.primaryColor;
        case 1:
          if (this.currentStep > 2) {
            return this.primaryColor;
          }
          return '#C4B5B5';
        case 2:
          if (this.currentStep > 6) {
            return this.primaryColor;
          }
          return '#C4B5B5';
        default:
          return '#C4B5B5';
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.width = this.progressWidth();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
});
