
import Vue from 'vue';
import NextStepButton from '@/components/UI/NextStepButton.vue';

export default Vue.extend({
  name: 'VerificationScreen',
  data: () => ({
    snackbar: false,
    a: '',
  }),
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    accessToken: {
      get(): string {
        return this.$store.getters.getAuftragAccessToken;
      },
      set(value: string) {
        this.$store.commit('setAuftragAccessToken', value);
      },
    },
  },
  components: {
    NextStepButton,
  },
  methods: {
    // clicked() {
    //   console.log('clicked');
    // },
    async fetchAuftrag() {
      const payload = {
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        accessToken: this.accessToken,
      };
      await this.$store.dispatch('fetchAuftragInfo', payload);
    },
    sendRequest() {
      if (this.accessToken === undefined) {
        this.snackbar = true;
        return;
      }
      if (this.accessToken.length !== 8) {
        this.snackbar = true;
      } else {
        this.fetchAuftrag();
      }
    },
    setAccessToken(value: string) {
      this.accessToken = value;
    },
  },
});
