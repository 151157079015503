
import Vue from 'vue';

export default Vue.extend({
  name: 'ChatPopup',
  data: () => ({
    showToolTip: false,
  }),
  computed: {
    launchChat: {
      get(): boolean {
        return this.$store.getters.getLaunchChat;
      },
      set(value: boolean) {
        this.$store.commit('setLaunchChat', value);
      },
    },
    unreadMessages: {
      get(): number {
        return this.$store.getters.getChatUnreadMessagesIds.length;
      },
    },
    showBadge: {
      get(): boolean {
        return this.unreadMessages !== 0;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    orgName: {
      get(): string {
        return this.$store.getters.getOrgName;
      },
    },
  },
  methods: {
    launch() {
      this.$router.push(`/${this.$route.params.orgString}/chat`);
      // this.launchChat = !this.launchChat;
      // this.$store.commit('setShowBackButton', true);
      // console.log('setbackbutton');
      // this.$store.dispatch('launchChatSession');
    },
    drag(e: MouseEvent) {
      const btn: HTMLElement | null = document.getElementById('chat-btn');
      btn!.style.transform = `translate(${e.pageX - 20}px, ${e.pageY - 20}px)`;
    },
    dragInit() {
      const btn = document.getElementById('chat-btn');
      btn!.draggable = true;
      // btn!.addEventListener('mousedown', () => {
      //   console.log('event listner registerd');
      //   document.addEventListener('mousemove', this.drag);
      // });
      // btn!.addEventListener('mouseup', () => {
      //   document.removeEventListener('mousemove', this.drag);
      // });
    },
  },
  mounted() {
    window.setTimeout(() => {
      this.showToolTip = true;
      window.setTimeout(() => {
        this.showToolTip = false;
      }, 4000);
    }, 1000);
    // this.dragInit();
  },
});
