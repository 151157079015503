
import Vue from 'vue';
import { RepairStep } from '@/store/auftrag/types';

export default Vue.extend({
  name: 'AuftragProgressScreen',
  data: () => ({
    intervalID: 0,
  }),
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    email: {
      get(): string {
        return this.$store.getters.getOrgEmail;
      },
    },
    currentRepairStep: {
      get(): number {
        return this.$store.getters.getAuftragCurrentRepairStep;
      },
    },
    steps: {
      get(): RepairStep[] {
        return this.$store.getters.getAuftragSteps;
      },
    },
    date: {
      get(): Date {
        return this.$store.getters.getAuftragCompletionDate;
      },
    },
    timeRaw: {
      get(): Date {
        return this.$store.getters.getAuftragCompletionTime;
      },
    },
    day: {
      get(): string {
        return this.date?.toLocaleDateString().split('/').join('.');
      },
    },
    time: {
      get(): string {
        return this.timeRaw?.toLocaleTimeString().split(':').slice(0, 2).join(':');
      },
    },
    showTime: {
      get(): boolean {
        return this.time !== undefined && this.time !== 'Invalid Date';
      },
    },
  },
  methods: {
    isDone(index: number) {
      if (this.steps[index].ended) {
        return this.primaryColor;
      }
      if (this.steps[index].started) {
        return '#FFFFFF';
      }
      return '#cacaca';
    },
    hasIcon(index: number) {
      if (this.steps[index].started && !this.steps[index].ended) {
        return 'mdi-play';
      }
      return undefined;
    },
  },
  mounted() {
    this.intervalID = window.setInterval(() => {
      console.log('update');
      const payload = {
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
      };
      this.$store.dispatch('fetchAuftragInfo', payload);
    }, 120 * 1000);
  },
  destroyed() {
    clearInterval(this.intervalID);
  },
});
