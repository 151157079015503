
import Vue from 'vue';
import ErrorHandler from '@/components/ErrorHandler/ErrorHandler.vue';
import TheRecaptcha from '@/components/RecaptchaForm/TheRecaptcha.vue';
import TheFooter from '@/components/TheFooter/TheFooter.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import { auth } from './firebase';

export default Vue.extend({
  name: 'App',
  computed: {
    restartOrContinue: {
      get(): boolean {
        return this.$store.state.restartOrContinue;
      },
    },
  },
  components: {
    ErrorHandler,
    // TheRecaptcha,
    TheFooter,
    NavBar,
  },
  mounted() {
    const params = {
      orgString: this.$route.params.orgString,
      token: this.$route.params.token,
    };
    // console.log(params);
    // intialize store bfore creating the top level component
    // this.$store.dispatch('checkLocalStorage', params);
    this.$store.commit('setUserUid', auth.currentUser?.uid);
  },
});
