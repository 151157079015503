import { MutationTree } from 'vuex';
import { DocumentState, SignDocument, SignResult } from '@/store/documents/types';

export const tmp = 0;

export const mutations: MutationTree<DocumentState> = {
  addDocument(state, document: SignDocument) {
    state.documents.push(document);
  },
  addDocuments(state, documents: any[]) {
    if (documents === [] || documents === undefined) {
      return;
    }
    state.documents = [];
    documents.forEach((document) => {
      const doc: SignDocument = {
        ...document.document,
        isSigned: false,
      };
      state.documents.push(doc);
    });
  },
  signDocument(state, payload: any) {
    const newSignResults: SignResult[] = [];
    state.documents[payload.index].signResults.forEach((element, index) => {
      const e = element;
      e.data = payload.signResults[index].data;
      e.height = payload.signResults[index].height;
      e.width = payload.signResults[index].width;
      e.image = payload.signResults[index].image;
      e.signInfo = payload.signResults[index].signInfo;
      newSignResults.push(e);
    });
    state.documents[payload.index].signResults = newSignResults;
    state.documents[payload.index].isSigned = true;
    // state.documents.splice(payload.index, 1, doc);
  },
  deleteDocuments(state, index: number) {
    state.documents.splice(index, 1);
  },
  setDocumentsCurrentStep(state, value: number) {
    state.currentStep = value;
  },
  setDocumentsTotalSteps(state, value: number) {
    state.totalSteps = value;
  },
};
