import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { NavBarState } from './types';

const tmp = 0;

const actions: ActionTree<NavBarState, RootState> = {
  damageReportNavBar({ commit }) {
    commit('setNavBarShowBackButton', true);
    commit('setNavBarShowMenu', false);
  },
  orgPageNavBar({ commit }) {
    commit('setNavBarShowBackButton', false);
    commit('setNavBarShowMenu', false);
  },
  auftragPageNavBar({ commit }) {
    commit('setNavBarShowBackButton', false);
    commit('setNavBarShowMenu', false);
  },
  appointmentNavBar({ commit }) {
    commit('setNavBarShowBackButton', true);
    commit('setNavBarShowMenu', false);
  },
  chatPageNavBar({ commit }) {
    commit('setNavBarShowBackButton', true);
    commit('setNavBarShowMenu', false);
  },
  clickBackBtn({ commit, getters }, pageName: string) {
    if (pageName.includes('DamageReport')) {
      const currentStep = getters.getDamageReportCurrentStep;
      if (currentStep > 0) {
        commit('setDamageReportCurrentStep', currentStep - 1);
      } else {
        window.history.back();
      }
      return;
    }
    if (pageName.includes('Appointment')) {
      const currentStep = getters.getAppointmentCurrentStep;
      if (currentStep > 0) {
        commit('setAppointmentCurrentStep', currentStep - 1);
      } else {
        window.history.back();
      }
      return;
    }
    if (pageName === 'Chat') {
      window.history.back();
    }
  },
  clickNextBtn({ commit, getters, dispatch }, pageName: string) {
    if (pageName.includes('DamageReport')) {
      dispatch('incrementDamageReportCurrentStep', !pageName.includes('Auftrag'));
      return;
    }
    if (pageName.includes('Appointment')) {
      dispatch('incrementAppointmentCurrentStep', pageName);
    }
  },
};

export {
  actions,
  tmp,
};
