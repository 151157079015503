import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/navbar/getters';
import { mutations } from '@/store/navbar/mutations';
import { actions } from '@/store/navbar/actions';
import { NavBarState } from '@/store/navbar/types';

const state: NavBarState = {
  showBackButton: false,
  showMenu: false,
};

export const tmp = 0;

export const navbar: Module<NavBarState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
