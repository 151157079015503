
import Vue from 'vue';
import ChatMessageBox from '@/components/ChatScreen/ChatMessageBox.vue';
import { ChatMessage } from '@/store/chat/types';

export default Vue.extend({
  name: 'ChatMessagesFeed',
  data: () => ({
    dividerAdded: false,
  }),
  components: {
    ChatMessageBox,
  },
  computed: {
    chat: {
      get(): Record<string, ChatMessage> {
        return this.$store.getters.getChatMessages;
      },
    },
    unreadMessages: {
      get(): string[] {
        return this.$store.getters.getChatUnreadMessagesIds;
      },
    },
  },
  methods: {
    showNewMessagesDivider(msgId: string): boolean {
      // console.log(`msg ID: ${msgId}`);
      if (this.dividerAdded) {
        // console.log('already added');
        return false;
      }
      if (this.unreadMessages.indexOf(msgId) > -1) {
        // console.log('new Message');
        this.dividerAdded = true;
        return true;
      }
      // console.log('Old msg');
      return false;
    },
    scrollDown() {
      const body = document.body;
      const html = document.documentElement;
      const height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      window.scroll({ top: height });
    },
  },
  mounted() {
    this.scrollDown();
  },
  updated() {
    this.scrollDown();
  },
});
