import axios, { AxiosRequestConfig } from 'axios';
import { ActionTree } from 'vuex';
import { configAxios, HOSTNAME } from '@/store/shared';
import { RootState } from '../types';
import { AuftragState } from './types';

export const tmp = 0;

export const actions: ActionTree<AuftragState, RootState> = {
  mutateAuftrag({ commit }, payload: any) {
    // set Auftrag Token
    commit('setAuftragToken', payload.token);

    // set Chatroom id
    if (payload.chatroomId !== undefined) {
      commit('setChatroomId', payload.chatroomId);
      commit('setEnableChat', true);
    }

    if (payload.repairStatus !== undefined) {
      // set repair process info
      commit('setAuftragCompletionDate', payload.repairStatus.completionDate);
      commit('setAuftragCompletionTime', payload.repairStatus.completionTime);
      commit('setAuftragCurrentRepairStep', payload.repairStatus.currentStep);
      commit('setAuftragSteps', payload.repairStatus.steps);
    }

    // set Damage Report steps
    // commit('setDamageReportActiveSteps', payload);

    // set Appropriate Action to be taken by the customer
    commit('setAuftragShowRepairStatus', payload.repairStatus !== undefined);
    commit('setAuftragMakeReport', payload.damageReport !== undefined);
    commit('setAuftragMakeViewingAppointment', payload.viewingAppointment !== undefined);
    commit('setAuftragMakeRepairAppointment', payload.repairAppointment !== undefined);
    commit('setAuftragSignDocs', payload.docs !== undefined);
    commit('setAuftragRequireCustomerInfo', payload.customer !== undefined);
    // set Documents to be signed
    commit('addDocuments', payload.docs);

    // check if Auftrag isComplete
    const isComplete: boolean = payload.repairStatus !== undefined;
    // Hide ContinueOrRestartAlertBox
    if (isComplete) {
      commit('setRestartOrContinue', false);
    }
    commit('setAuftragIsComplete', isComplete);
    commit('setUtilsLoading', false);
  },
  async fetchAuftragInfo({ commit, dispatch }, payload): Promise<void> {
    commit('setUtilsLoading', true);

    const config: AxiosRequestConfig = await configAxios(payload.accessToken);
    const url = `${HOSTNAME}/enduser/org/${payload.orgString}/auftrag/${payload.token}/`;

    axios.get(url, config)
      .then((response) => {
        // // console.log(response);
        // handle verification
        dispatch('utilsHandleSuccussfullVerification');

        // mutate Auftrag
        const data = response.data;
        data.token = payload.token;
        // // console.log(data);
        dispatch('mutateAuftrag', data);
        // setup chat
        if (data.chatroomId) {
          // console.log('chat setup');
          dispatch('listenForChatMessages');
          dispatch('connectChatClient');
          dispatch('disconnectChatClient');
        }
      })
      .catch((error) => {
        dispatch('utilsHandleAjaxError', error);
      });
  },
};
