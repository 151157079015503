import axios, { AxiosRequestConfig } from 'axios';
import { ActionTree } from 'vuex';
import {
  readFile,
} from '@joyfulljs/imagemin';
import {
  configAxios, getRecaptchaToken, getUrl,
} from '@/store/shared';
import {
  storageRef, uploadBytes, storage, auth,
} from '@/firebase';
import { germanNumberPlatePattern, otherNumberPlatePattern } from '@/components/ReportStepper/NumberPlate.vue';
import { RootState } from '../types';
import { DamageReportState } from './types';

export const tmp = 0;

export const actions: ActionTree<DamageReportState, RootState> = {
  initDamageReportStepper({ commit, state }) {
    if (state.isFinished === true) {
      // wipe State
      commit('initDamageReport');
      commit('initUserState');
    }
  },
  incrementDamageReportCurrentStep({ getters, commit, dispatch }, request: boolean) {
    const currentStep = getters.getDamageReportCurrentStep;
    const totalSteps = getters.getDamageReportTotalSteps;
    switch (currentStep) {
      case 0: { // KZ or Fahrueugschein
        if (!request) {
          // fahrzeugschein
          commit('setDamageReportCurrentStep', 1);
          return;
        }
        // const pattern = /^[a-zäöü]{1,3}(\s|-)*[a-zäöü]{1,2}(\s|-)*[0-9]{1,4}(e)?$/i;
        const kzCountry = getters.getDamageReportVehicleKzCountry;
        let pattern;
        if (kzCountry === 'other') {
          pattern = otherNumberPlatePattern;
        } else {
          pattern = germanNumberPlatePattern;
        }
        if (pattern.test(getters.getDamageReportVehicleKz)) {
          commit('setDamageReportCurrentStep', 1);
        } else {
          commit('setUtilsSnackbarMessage', 'Bitte geben Sie ein gültiges Kennzeichen ein!');
          commit('setUtilsShowSnackbar', true);
        }
        return;
      }
      case totalSteps - 3: {
        const requireCustomer = request || getters.getAuftragRequireCustomerInfo;
        if (!getters.isUserInfoComplete && requireCustomer) {
          commit('setUtilsSnackbarMessage', 'Bitte geben Sie Ihre Kontaktinformationen ein!');
          commit('setUtilsShowSnackbar', true);
        } else {
          commit('setDamageReportCurrentStep', currentStep + 1);
        }
        return;
      }
      case totalSteps - 2: {
        const requireCustomer = request || getters.getAuftragRequireCustomerInfo;
        if (requireCustomer) {
          dispatch('sendUserInfo', {
            orgString: getters.getOrgString,
            token: getters.getAuftragToken,
          });
        }
        commit('setDamageReportCurrentStep', currentStep + 1);
        return;
      }
      case totalSteps - 1: {
        dispatch('sendDamageReportIsFinished', {
          orgString: getters.getOrgString,
          token: getters.getAuftragToken,
        });
        commit('setDamageReportCurrentStep', currentStep + 1);
        return;
      }
      default: {
        commit('setDamageReportCurrentStep', currentStep + 1);
        break;
      }
    }
  },
  async sendDamageReportIsFinished({ commit, dispatch, getters }, payload): Promise<void> {
    // show loading screen
    commit('setUtilsLoading', true);
    // hide backbtn
    commit('setNavBarShowBackButton', false);
    // set Request header
    const config: AxiosRequestConfig = await configAxios();
    // // console.log('sending Auftrag Request');

    // recaptcha token
    const token = await getRecaptchaToken('damage_report');

    const auftrag = {
      isFinished: true,
      recaptchaToken: token,
      vehicleKz: getters.getDamageReportVehicleKz,
      insurance: getters.getDamageReportVehicleInsuranceWithInsuranceType,
      notes: getters.getDamageReportNotes,
      damageNumber: getters.getDamageReportNumber,
    };
    // console.log(auftrag);
    const url = getUrl(payload);
    // console.log(url);
    axios.post(url, auftrag, config)
      .then((response) => {
        // console.log(response);
        commit('setUtilsLoading', false);
        // mark reportStepper as finished
        commit('setDamageReportIsFinished', true);
        dispatch('initDamageReportStepper');
      })
      .catch((error) => {
        dispatch('utilsHandleAjaxError', error);
      });
  },
  async uploadDamageReportImage({ getters, commit, dispatch }, payload) {
    // show loading screen
    commit('setUtilsImageUploadLoading', true);
    // console.log(payload);

    const timestamp = Date.now();
    const array: string[] = payload.file.name.split('.');
    const filename = `${array.slice(0, array.length - 1).join()}_${timestamp}.${array[array.length - 1]}`;

    // const uid = getters.getUserUid;
    const uid = auth.currentUser?.uid;

    if (!uid) {
      // error
      commit('setUtilsImageUploadLoading', false);
      // console.error('error');
      return;
    }
    // const ref = storageRef(storage, `userUploads/${uid}/${payload.file.name}`);
    const ref = storageRef(storage, `userUploads/${uid}/${filename}`);
    // console.log(ref);
    const result = await uploadBytes(ref, payload.file);
    const base64 = await readFile(payload.file);
    dispatch('sendDamageReportImage', {
      gcpPath: `gs://reparaturinfo-prod.appspot.com/${result.ref.fullPath}`,
      description: payload.description,
      mimeType: payload.file.type,
      filename,
      file: base64,
      id: payload.id,
      orgString: payload.orgString,
      token: payload.token,
      attribute: payload.attribute,
      mutation: payload.mutation,
    });
  },
  async sendDamageReportImage({ commit }, payload) {
    // console.log(payload);
    // set Request header
    const config: AxiosRequestConfig = await configAxios();
    const data: Record<string, any> = {};
    data[payload.attribute] = {
      gcpPath: payload.gcpPath,
      description: payload.description,
      mimeType: payload.mimeType,
      filename: payload.filename,
    };
    const url = getUrl(payload);
    axios.post(url, data, config)
      .then((response) => {
        // console.log(response);
        commit(payload.mutation, {
          id: payload.id,
          data: payload,
        });
        commit('setUtilsImageUploadLoading', false);
      })
      .catch((error) => {
        // console.log(error);
        commit('setUtilsImageUploadLoading', false);
      });
  },
  async deleteDamageReportImage({ commit }, payload) {
    // console.log(payload);
    // set Request header
    const config: AxiosRequestConfig = await configAxios();
    config.data = {
      filename: payload.filename,
    };
    const url = getUrl(payload);
    axios.delete(url, config)
      .then((response) => {
        // console.log(response);
        commit(payload.mutation, payload.id);
        commit('setUtilsImageUploadLoading', false);
      })
      .catch((error) => {
        // console.log(error);
        commit('setUtilsImageUploadLoading', false);
      });
  },
};
