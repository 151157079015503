import { MutationTree } from 'vuex';
import { AppointmentSlotsState } from './types';

export const tmp = 0;

export const mutations: MutationTree<AppointmentSlotsState> = {
  initAppointmentState(state) {
    state.viewingAppointment = '';
    state.repairAppointment = '';
    state.firstWeek = {};
    state.firstWeekLoading = false;
    state.secondWeek = {};
    state.secondWeekLoading = false;
    state.thirdWeek = {};
    state.thirdWeekLoading = false;
    state.fourthWeek = {};
    state.fourthWeekLoading = false;
    state.isViewing = true;
    state.currentStep = 0;
  },
  setViewingAppointment(state, value: string) {
    state.viewingAppointment = value;
  },
  setViewingAppointmentDate(state, date: string) {
    const viewingAppointment: Date = new Date();
    viewingAppointment.setDate(Number.parseInt(date.split('-')[2], 10));
    viewingAppointment.setMonth(Number.parseInt(date.split('-')[1], 10) - 1);
    viewingAppointment.setFullYear(Number.parseInt(date.split('-')[0], 10));
    viewingAppointment.setHours(7);
    viewingAppointment.setMinutes(0);
    viewingAppointment.setSeconds(0);
    state.viewingAppointment = viewingAppointment.toISOString();
  },
  setRepairAppointment(state, value: string) {
    state.repairAppointment = value;
  },
  setRepairAppointmentDate(state, date: string) {
    const repairAppointment: Date = new Date();
    repairAppointment.setDate(Number.parseInt(date.split('-')[2], 10));
    repairAppointment.setMonth(Number.parseInt(date.split('-')[1], 10) - 1);
    repairAppointment.setFullYear(Number.parseInt(date.split('-')[0], 10));
    repairAppointment.setHours(0);
    repairAppointment.setMinutes(0);
    repairAppointment.setSeconds(0);
    state.repairAppointment = repairAppointment.toISOString();
  },
  setAppointmentIsViewing(state, value: boolean) {
    state.isViewing = value;
  },
  setAppointmentsFirstWeek(state, value: any) {
    state.firstWeek = value;
  },
  setAppointmentsSecondWeek(state, value: any) {
    state.secondWeek = value;
  },
  setAppointmentsThirdWeek(state, value: any) {
    state.thirdWeek = value;
  },
  setAppointmentsFourthWeek(state, value: any) {
    state.fourthWeek = value;
  },
  setAppointmentsWeek(state, payload: any) {
    switch (payload.index) {
      case 1: {
        state.secondWeek = payload.value;
        return;
      }
      case 2: {
        state.thirdWeek = payload.value;
        return;
      }
      case 3: {
        state.fourthWeek = payload.value;
        return;
      }
      default: {
        state.firstWeek = payload.value;
        break;
      }
    }
  },
  setAppointmentsWeekLoading(state, payload: any) {
    switch (payload.index) {
      case 1: {
        state.secondWeekLoading = payload.value;
        return;
      }
      case 2: {
        state.thirdWeekLoading = payload.value;
        return;
      }
      case 3: {
        state.fourthWeekLoading = payload.value;
        return;
      }
      default: {
        state.firstWeekLoading = payload.value;
        break;
      }
    }
  },
  setAppointmentTotalsteps(state, value: number) {
    state.totalSteps = value;
  },
  setAppointmentCurrentStep(state, value: number) {
    state.currentStep = value;
  },
};
