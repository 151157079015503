import { render, staticRenderFns } from "./ViewingAppointment.vue?vue&type=template&id=314827c4&scoped=true&"
import script from "./ViewingAppointment.vue?vue&type=script&lang=ts&"
export * from "./ViewingAppointment.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "314827c4",
  null
  
)

export default component.exports