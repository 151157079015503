
import Vue from 'vue';
import ImageUploaderProgressScreen from '@/components/ReportStepper/ImageUploaderProgressScreen.vue';

export default Vue.extend({
  name: 'ExtraImagesUploader',
  data: () => ({
    img: '',
    overlay: false,
  }),
  components: {
    ImageUploaderProgressScreen,
  },
  computed: {
    images: {
      get(): Record<string, any> {
        return this.$store.getters.getDamageReportExtraImages;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.getters.getUtilsImageUploadLoading;
      },
    },
  },
  methods: {
    launchFileSelector() {
      const element: HTMLElement = document.getElementById('extra-input') as HTMLElement;
      element.click();
    },
    uploadFile(file: File) {
      const payload = {
        file,
        description: 'zusätzliche Bilder',
        id: Math.random().toString(),
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        attribute: 'image',
        mutation: 'setDamageReportExtraImage',
      };
      this.$store.dispatch('uploadDamageReportImage', payload);
    },
    displayImg(img: string) {
      this.img = img;
      this.overlay = true;
    },
    hideImg() {
      this.overlay = false;
    },
    deleteFile(image: Record<string, any>) {
      const payload = {
        filename: image.filename,
        id: image.id,
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        attribute: 'image',
        mutation: 'deleteDamageReportExtraImage',
      };
      this.$store.dispatch('deleteDamageReportImage', payload);
      // this.$store.commit('deleteDamageReportExtraImage', image.id);
      // this.$store.dispatch('deleteFile', {
      // });
    },
  },
});
