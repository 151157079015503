
import Vue from 'vue';
import ChatMessagesFeed from '@/components/ChatScreen/ChatMessagesFeed.vue';
import ChatTextBox from '@/components/ChatScreen/ChatTextBox.vue';

export default Vue.extend({
  name: 'ChatPage',
  components: {
    ChatMessagesFeed,
    ChatTextBox,
  },
  methods: {},
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    name: {
      get(): string {
        return this.$store.getters.getOrgName;
      },
    },
    chatroomId: {
      get(): string {
        return this.$store.getters.getChatroomId;
      },
    },
    showBackButton: {
      get(): boolean {
        return this.$store.getters.getShowBackButton;
      },
    },
  },
  created() {
    this.$store.dispatch('chatPageNavBar');
  },
});
