
import Vue from 'vue';

export default Vue.extend({
  name: 'SuccessScreen',
  props: {
    title: {
      type: String,
      default: 'Vielen Dank für das Einreichen Ihres Schadens.',
    },
    subTitle: {
      type: String,
      default: 'Wir melden uns in Kürze bei Ihnen.',
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
});
