import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ChatState, ChatMessage } from '@/store/chat/types';

export const tmp = 0;

export const mutations: MutationTree<ChatState> = {
  addChatMessage(state, data: any) {
    Vue.set(state.messages, data.id, data.message);
    if (data.message.seen === false && data.message.sender === 'org') {
      state.unreadMessagesIds.push(data.id);
    }
  },
  setEnableChat(state, value: boolean) {
    state.enableChat = value;
  },
  setLaunchChat(state, value: boolean) {
    state.launchChat = value;
  },
  setChatroomId(state, value: string) {
    state.chatroomId = value;
  },
  addBatchChatMessages(state, data: Record<string, any>[]) {
    Object.entries(data).forEach((value: Record<string, any>, index: number) => {
      Vue.set(state.messages, value[0], value[1]);
    });
  },
  setChatMessages(state, data: Record<string, any>) {
    Vue.set(state, 'messages', <Record<string, ChatMessage>>data);
  },
  updateChatMessage(state, data: Record<string, any>) {
    Vue.set(state.messages, data.id, data.message);
    // if (data.message.seen === true && data.message.from === 'org') {
    //   state.unreadMessages += 1;
    // }
  },
  // markChatMessageAsSeen(state, data) {

  // },
  setChatUnreadMessgaes(state, value: string[]) {
    state.unreadMessagesIds = value;
  },
  addChatUnreadMessages(state, id: string) {
    state.unreadMessagesIds.push(id);
  },
  removeChatUnreadMessages(state, id: string) {
    state.unreadMessagesIds.splice(state.unreadMessagesIds.indexOf(id), 1);
  },
  setLastMessageRead(state, id: string) {
    state.lastMessageRead = id;
  },
};
