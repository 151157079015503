
import Vue from 'vue';
import TheMenu from '@/components/NavBar/TheMenu.vue';

export default Vue.extend({
  name: 'NavBar',
  components: {
    TheMenu,
  },
  computed: {
    showMenu: {
      get(): boolean {
        return this.$store.getters.getNavBarShowMenu;
      },
      set(value: boolean) {
        this.$store.commit('setNavBarShowMenu', value);
      },
    },
    showBackButton: {
      get(): boolean {
        return this.$store.getters.getNavBarShowBackButton;
      },
    },
    orgString: {
      get(): string {
        return this.$route.params.orgString;
      },
    },
    logo: {
      get(): string {
        return this.$store.getters.getOrgLogo;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    secondaryColor: {
      get(): string {
        return this.$store.getters.getOrgSecondaryColor;
      },
    },
    logoClasses: {
      get(): string {
        return this.showBackButton ? 'logo logo-moved' : 'logo';
      },
    },
    currentStep: {
      get(): number {
        return this.$store.getters.getStepperCurrentStep;
      },
      set(value: number) {
        this.$store.commit('setStepperCurrentStep', value);
      },
    },
    totalSteps: {
      get(): number {
        return this.$store.getters.getStepperTotalSteps;
      },
    },
  },
  methods: {
    menu() {
      this.showMenu = true;
    },
    hideMenu() {
      this.showMenu = false;
    },
    launchReport() {
      this.$router.push(`/${this.orgString}/schadenaufnahme`);
    },
    launchViewingAppointment() {
      this.$router.push(`/${this.orgString}/besichtigung`);
    },
    launchRepairAppointment() {
      this.$router.push(`/${this.orgString}/reparatur`);
    },
    homePage() {
      this.$router.push(`/${this.orgString}`);
    },
    clickBackBtn() {
      this.$store.dispatch('clickBackBtn', this.$route.name);
    },
  },
});
