
import Vue from 'vue';

export default Vue.extend({
  name: 'ReviewScreenCustomer',
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    name: {
      get(): string {
        return this.$store.getters.getUserName;
      },
    },
    styledName: {
      get(): string {
        return `${this.name.split(' ').map((value: string) => (
          `${value.charAt(0).toUpperCase() + value.slice(1)}`
        )).join(' ')}`;
      },
    },
    email: {
      get(): string {
        return this.$store.getters.getUserEmail;
      },
    },
    phone: {
      get(): string {
        return this.$store.getters.getUserPhone;
      },
    },
    addressTitle: {
      get(): string {
        return `${this.street.charAt(0).toUpperCase() + this.street.slice(1)} ${this.houseNr}`;
      },
    },
    addressSubTitle: {
      get(): string {
        return `${this.zip} ${this.city.charAt(0).toUpperCase() + this.city.slice(1)}`;
      },
    },
    street: {
      get(): string {
        return this.$store.getters.getUserStreet;
      },
    },
    houseNr: {
      get(): string {
        return this.$store.getters.getUserHouseNumber;
      },
    },
    city: {
      get(): string {
        return this.$store.getters.getUserCity;
      },
    },
    zip: {
      get(): string {
        return this.$store.getters.getUserZip;
      },
    },
  },
  methods: {
  },
});
