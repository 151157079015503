import { MutationTree } from 'vuex';
import { OrgState } from '@/store/organisation/types';
import { setDocTitleAndFavicon } from '../shared';

export const tmp = 0;

export const mutations: MutationTree<OrgState> = {
  setOrgPrimaryColor(state, color: string) {
    state.primaryColor = color;
  },
  setOrgSecondaryColor(state, color: string) {
    state.secondaryColor = color;
  },
  setOrgString(state, value: string) {
    state.orgString = value;
  },
  setOrgLogo(state, logoUrl: string) {
    state.logo = logoUrl;
  },
  setOrgFavicon32(state, faviconUrl: string) {
    // Change Doc Icon
    setDocTitleAndFavicon(undefined, faviconUrl, 32);
    state.favicon32 = faviconUrl;
  },
  setOrgFavicon16(state, faviconUrl: string) {
    // Change Doc Icon
    setDocTitleAndFavicon(undefined, faviconUrl, 16);
    state.favicon16 = faviconUrl;
  },
  setOrgName(state, value: string) {
    // change Doc Title
    setDocTitleAndFavicon(value);
    state.name = value;
  },
  setOrgId(state, value: string) {
    state.orgId = value;
  },
  setOrgEmail(state, value: string) {
    state.email = value;
  },
  setOrgLoading(state, value: boolean) {
    state.orgLoading = value;
  },
  setOrgError(state, value: boolean) {
    state.orgError = value;
  },
  setOrgAGB(state, value: string) {
    state.agb_url = value;
  },
  setOrgDatenschutz(state, value: string) {
    state.datenschutz_url = value;
  },
  setOrgImpressum(state, value: string) {
    state.impressum_url = value;
  },
  setOrgAcceptsRequests(state, value: boolean) {
    state.acceptsRequests = value;
  },
  setOrgAcceptsAppointments(state, value: boolean) {
    state.acceptsAppointments = value;
  },
};
