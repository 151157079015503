
import Vue from 'vue';

export default Vue.extend({
  name: 'NextStepButton',
  props: {
    buttonText: {
      type: String,
      default: 'Nächster Schritt',
    },
  },
  methods: {
    notify() {
      this.$emit('notify');
    },
  },
});
