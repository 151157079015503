
import Vue from 'vue';

export default Vue.extend({
  name: 'ReviewScreenVehicle',
  props: {
    showImages: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    kennzeichen: {
      get(): string {
        return this.$store.getters.getDamageReportVehicleKz;
      },
    },
    insurance: {
      get(): string {
        return this.$store.getters.getDamageReportVehicleInsuranceWithInsuranceType;
      },
    },
    damageNumber: {
      get(): string {
        return this.$store.getters.getDamageReportNumber;
      },
    },
    notes: {
      get(): string {
        return this.$store.getters.getDamageReportNotes;
      },
    },
    images: {
      get(): Record<string, any>[] {
        return this.$store.getters.getDamageReportAllImages;
      },
    },
  },
});
