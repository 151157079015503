import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/appointment/getters';
import { mutations } from '@/store/appointment/mutations';
import { actions } from '@/store/appointment/actions';
import { AppointmentSlotsState } from '@/store/appointment/types';

const state: AppointmentSlotsState = {
  viewingAppointment: '',
  repairAppointment: '',
  isViewing: true,
  firstWeek: '',
  firstWeekLoading: false,
  secondWeek: '',
  secondWeekLoading: false,
  thirdWeek: '',
  thirdWeekLoading: false,
  fourthWeek: '',
  fourthWeekLoading: false,
  currentStep: 0,
  totalSteps: -1,
};

export const tmp = 0;

export const appointmentSlots: Module<AppointmentSlotsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
