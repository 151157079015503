
import Vue from 'vue';

export default Vue.extend({
  name: 'ExtraInfos',
  data: () => ({
    valid: true,
    snackbar: false,
    insuranceTypes: ['Haftpflicht', 'Teilkasko', 'Vollkasko'],
  }),
  props: {
    request: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    insurance: {
      get(): string {
        return this.$store.getters.getDamageReportInsurance;
      },
      set(value: string) {
        this.$store.commit('setDamageReportInsurance', value);
      },
    },
    insuranceType: {
      get(): 'Haftpflicht' | 'Teilkasko' | 'Vollkasko' {
        return this.$store.getters.getDamageReportInsuranceType;
      },
      set(value: 'Haftpflicht' | 'Teilkasko' | 'Vollkasko' | undefined) {
        this.$store.commit('setDamageReportInsuranceType', value);
      },
    },
    damageNumber: {
      get(): string {
        return this.$store.getters.getDamageReportNumber;
      },
      set(value: string) {
        this.$store.commit('setDamageReportNumber', value);
      },
    },
    notes: {
      get(): string {
        return this.$store.getters.getDamageReportNotes;
      },
      set(value: string) {
        this.$store.commit('setDamageReportNotes', value);
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
  },
  methods: {
    setNotes(value: string) {
      this.notes = value;
    },
    setInsurance(value: string) {
      this.insurance = value;
    },
    setDamageNumber(value: string) {
      this.damageNumber = value;
    },
    setInsuranceType(value: 'Haftpflicht' | 'Teilkasko' | 'Vollkasko' | undefined) {
      this.insuranceType = value;
    },
  },
});
