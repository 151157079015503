import { ActionTree } from 'vuex';
import {
  database, ref, push, onChildAdded, endAt, startAt, query, onValue, set, onDisconnect, update,
  onChildChanged,
} from '@/firebase';
import { RootState } from '../types';
import { ChatState } from './types';

export const tmp = 0;

export const actions: ActionTree<ChatState, RootState> = {
  listenForChatMessages({ getters, commit, dispatch }) {
    const chatroomRef = ref(
      database,
      `organisations/${getters.getOrgId}/chatrooms/${getters.getChatroomId}/messages`,
    );
    onChildAdded(chatroomRef, (data) => {
      // add message to messages
      // // console.log(`new msg ${data.key}`);
      commit('addChatMessage', { id: data.key, message: data.val() });
      // dispatch('setLastMessageRead', data.key);
      // dispatch('markMessageAsRead', data.key);
    });
    onChildChanged(chatroomRef, (data) => {
      // // console.log(`msg ${data.key} seen`);
      commit('updateChatMessage', { id: data.key, message: data.val() });
    });
  },
  markMessageAsRead({ getters, commit }, messageId: string) {
    if (getters.getChatMessages[messageId].sender !== 'customer' && !getters.getChatMessages[messageId].seen) {
      const messageRef = ref(
        database,
        `organisations/${getters.getOrgId}/chatrooms/${getters.getChatroomId}/messages/${messageId}`,
      );
      update(messageRef, {
        seen: true,
      });
      commit('removeChatUnreadMessages', messageId);
    }
  },
  fetchChatMessages({ getters, commit }, index?: number) {
    let i = index;
    if (i === undefined) {
      i = 0;
    }
    if (i < 0) {
      i = 0;
    }
    const chatroomId: string = getters.getChatroomId;
    const orgId: string = getters.getOrgId;
    const recentPostsRef = query(
      ref(database, `organisations/${orgId}/chatrooms/${chatroomId}/messages`),
    );
    // set list of messages
    onValue(recentPostsRef, (snapshot) => {
      const data = snapshot.val();
      // console.log(data);
      if (index === 0) {
        commit('setChatMessages', data);
      } else {
        commit('addBatchChatMessages', data);
      }
    });
  },
  sendChatMessage({ getters }, msg: string) {
    const chatroomId: string = getters.getChatroomId;
    const orgId: string = getters.getOrgId;
    push(ref(database, `organisations/${orgId}/chatrooms/${chatroomId}/messages`), {
      message: msg,
      sender: 'customer',
      senderId: getters.getUserUid,
      seen: false,
      timestamp: Date.now(),
    });
  },
  connectChatClient({ getters }) {
    const chatroomId: string = getters.getChatroomId;
    const orgId: string = getters.getOrgId;
    if (chatroomId !== '') {
      update(ref(database, `organisations/${orgId}/chatrooms/${chatroomId}`), {
        customerConnected: true,
      });
    }
  },
  disconnectChatClient({ getters }) {
    const chatroomId: string = getters.getChatroomId;
    const orgId: string = getters.getOrgId;
    if (chatroomId !== '') {
      onDisconnect(ref(database, `organisations/${orgId}/chatrooms/${chatroomId}`)).update({
        customerConnected: false,
      });
    }
  },
  launchChatSession({ commit }): void {
    commit('setStepperCurrentStep', 0);
    commit('setLaunchChat', true);
    commit('setShowBackButton', true);
  },
  setLastMessageRead({ getters, commit }, messageId: string) {
    const msgs = getters.getChatMessages;
    const newLastMessage = msgs[messageId];
    if (newLastMessage.seen === true || newLastMessage.sender !== 'org') {
      return;
    }
    const lastMessageId = getters.getChatLastMessageRead;
    if (!lastMessageId) {
      commit('setLastMessageRead', messageId);
      return;
    }
    const lastMessage = msgs[lastMessageId];
    if (newLastMessage.timestamp > lastMessage.timestamp) {
      commit('setLastMessageRead', messageId);
    }
  },
};
