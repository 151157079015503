import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=6ec935f2&scoped=true&"
import script from "./TheFooter.vue?vue&type=script&lang=ts&"
export * from "./TheFooter.vue?vue&type=script&lang=ts&"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=6ec935f2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec935f2",
  null
  
)

export default component.exports