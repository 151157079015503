
import Vue from 'vue';

export default Vue.extend({
  name: 'ConnectionLostScreen',
  props: {
    title: {
      type: String,
      default: 'Keine Internetverbindung',
    },
    subTitle: {
      type: String,
      default: 'Verbindung zum Server konnte nicht hergestellt werden. Ihre Anfrage wird zugestellt, sobald die Verbindung wiederhergestellt ist.',
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    primaryColor: {
      get(): boolean {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
  },
  mounted() {
    this.$store.commit('setUtilsLoading', false);
    this.$store.commit('setUtilsAbsoluteLoading', false);
  },
});
