import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/auftrag/getters';
import { mutations } from '@/store/auftrag/mutations';
import { actions } from '@/store/auftrag/actions';
import { AuftragState } from '@/store/auftrag/types';

const state: AuftragState = {
  token: '',
  isComplete: false,
  completionDate: new Date(),
  completionTime: new Date(),
  steps: [],
  requireVerification: false,
  accessToken: '',
  showRepairStatus: false,
  signDocs: false,
  makeRepairAppointment: false,
  makeViewingAppointment: false,
  makeReport: false,
  requireCustomerInfo: true,
};

export const tmp = 0;

export const auftrag: Module<AuftragState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
