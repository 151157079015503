
import Vue from 'vue';
import ImageUploaderProgressScreen from '@/components/ReportStepper/ImageUploaderProgressScreen.vue';

export default Vue.extend({
  name: 'VehicleRegistrationUploader',
  data: () => ({
    overlay: false,
    img: '',
  }),
  components: {
    ImageUploaderProgressScreen,
  },
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    frontSide: {
      get(): Record<string, any> {
        return this.$store.getters.getDamageReportVehicleRegistrationSide('front');
      },
    },
    frontSideEmpty: {
      get(): boolean {
        return Object.keys(this.frontSide).length === 0;
      },
    },
    backSide: {
      get(): Record<string, any> {
        return this.$store.getters.getDamageReportVehicleRegistrationSide('back');
      },
    },
    backSideEmpty: {
      get(): boolean {
        return Object.keys(this.backSide).length === 0;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.getters.getUtilsImageUploadLoading;
      },
    },
  },
  methods: {
    launchFileSelector(id: string) {
      const element: HTMLElement = document.getElementById(id) as HTMLElement;
      element.click();
    },
    uploadFile(file: File, id: ('front' | 'back'), description: string) {
      const payload = {
        file,
        description,
        id,
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        attribute: id === 'front' ? 'vehicleRegistration' : 'vehicleRegistrationBack',
        mutation: 'setDamageReportVehicleRegistration',
      };
      this.$store.dispatch('uploadDamageReportImage', payload);
    },
    uploadFrontSide(file: File) {
      this.uploadFile(file, 'front', 'Fahrzeugschein Vorderseite');
    },
    uploadBackSide(file: File) {
      this.uploadFile(file, 'back', 'Fahrzeugschein Rückseite');
    },
    showImage(image: string) {
      this.img = image;
      this.overlay = true;
    },
    hideImage() {
      this.overlay = false;
    },
  },
});
