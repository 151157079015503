import { MutationTree } from 'vuex';
import { ErrorType, UtilsState } from '@/store/utils/types';

export const tmp = 0;

export const mutations: MutationTree<UtilsState> = {
  setDamageReportCheckVehicleDrivable(state, value: boolean) {
    state.checkVehicleDrivable = value;
  },
  setUtilsLoading(state, value: boolean) {
    state.loading = value;
  },
  setUtilsLoadingAvailability(state, value: boolean) {
    state.loadingAvailability = value;
  },
  setUtilsAbsoluteLoading(state, value: boolean) {
    state.absoluteLoading = value;
  },
  setUtilsCriticalError(state, value: boolean) {
    state.criticalError = value;
  },
  setUtilsCriticalErrorType(state, value: ErrorType) {
    state.criticalErrorType = value;
  },
  setUtilsCriticalErrorMessage(state, value: string) {
    state.criticalErrorMessage = value;
  },
  setUtilsNonCriticalError(state, value: boolean) {
    state.nonCriticalError = value;
  },
  setUtilsNonCriticalErrorType(state, value: ErrorType) {
    state.nonCriticalErrorType = value;
  },
  setUtilsNonCriticalErrorMessage(state, value: string) {
    state.nonCriticalErrorMessage = value;
  },
  setUtilsResponseStatusCode(state, value: number) {
    state.responseStatusCode = value;
  },
  setUtilsImageUploadLoading(state, value: boolean) {
    state.imageUploadLoading = value;
  },
  setUtilsDocSignUploadLoading(state, value: boolean) {
    state.docSignUploadLoading = value;
  },
  setStepperCurrentStep(state, value: number) {
    state.stepperCurrentStep = value;
  },
  setStepperTotalSteps(state, value: number) {
    state.stepperTotalSteps = value;
  },
  setUtilsShowSnackbar(state, value: boolean) {
    state.showSnackbar = value;
  },
  setUtilsSnackbarMessage(state, value: string) {
    state.snackbarMessage = value;
  },
  setUtilsConnectionLost(state, value: boolean) {
    state.connectionLost = value;
  },
};
