import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { DamageReportState } from './types';

export const tmp = 0;

export const getters: GetterTree<DamageReportState, RootState> = {
  getDamageReportVehicleKz(state): string {
    return state.vehicleKz;
  },
  getDamageReportVehicleKzCountry(state): 'germany' | 'other' {
    return state.kzCountry;
  },
  getDamageReportInsurance(state): string {
    return state.insurance;
  },
  getDamageReportInsuranceType(state): 'Haftpflicht' | 'Vollkasko' | 'Teilkasko' | undefined {
    return state.insuranceType;
  },
  getDamageReportVehicleInsuranceWithInsuranceType(state): string {
    return `${state.insurance}${state.insuranceType ? ` (${state.insuranceType})` : ''}`;
  },
  getDamageReportNotes(state): string {
    return state.notes;
  },
  getDamageReportNumber(state): string {
    return state.damageNumber;
  },
  getDamageReportVehicleRegistration(state): Record<string, any> {
    return state.vehicleRegistration;
  },
  getDamageReportVehicleRegistrationSide(state) {
    return (id: string): any => (
      state.vehicleRegistration[id]
    );
  },
  getDamageReportVehicleDashboard(state): Record<string, any> {
    return state.vehicleDashboard;
  },
  getDamageReportFarImages(state): Record<string, any> {
    return state.farImages;
  },
  getDamageReportFarImage(state) {
    return (id: string): any => (
      state.farImages[id]
    );
  },
  getDamageReportNearImages(state): Record<string, any> {
    return state.nearImages;
  },
  getDamageReportExtraImages(state): Record<string, any> {
    return state.extraImages;
  },
  getDamageReportOverviewImage(state): Record<string, any> {
    return state.overviewImage;
  },
  getDamageReportIsFinished(state) {
    return state.isFinished;
  },
  getDamageReportCurrentStep(state): number {
    return state.currentStep;
  },
  getDamageReportTotalSteps(state): number {
    return state.totalSteps;
  },
  getDamageReportAllImages(state): Record<string, any>[] {
    const images = [
      state.vehicleRegistration.front,
      state.vehicleRegistration.back,
      state.vehicleDashboard,
      state.farImages['front-left'],
      state.farImages['back-right'],
      state.overviewImage,
    ];
    Object.entries(state.nearImages).forEach((value) => {
      images.push(value[1]);
    });
    Object.entries(state.extraImages).forEach((value) => {
      images.push(value[1]);
    });
    // console.log(images);
    return images.filter((value) => (Object.keys(value).length > 0));
  },
};
