
import Vue from 'vue';
import AppointmentBooker from '@/components/AppointmentBooker/AppointmentBooker.vue';

export default Vue.extend({
  name: 'ViewingAppointment',
  data: () => ({}),
  components: {
    AppointmentBooker,
  },
  computed: {
    orgString: {
      get(): string {
        return this.$route.params.orgString;
      },
    },
    token: {
      get(): string {
        return this.$route.params.token;
      },
    },
    isViewing: {
      get(): boolean {
        return this.$store.getters.getAppointmentIsViewing;
      },
      set(value: boolean) {
        this.$store.commit('setAppointmentIsViewing', value);
      },
    },
    orgAcceptsAppointments: {
      get(): boolean {
        return this.$store.getters.getOrgAcceptsAppointments;
      },
    },
    bookViewingAppointment: {
      get(): boolean {
        return this.$store.getters.getAuftragMakeViewingAppointment;
      },
    },
    request: {
      get(): boolean {
        return this.token === undefined;
      },
    },
  },
  created() {
    this.isViewing = true;
    if (this.request && !this.orgAcceptsAppointments) {
      this.$router.push(`/${this.orgString}`);
      return;
    }
    if (!this.request && !this.bookViewingAppointment) {
      this.$router.push(`/${this.orgString}/${this.token}`);
    }
  },
});
