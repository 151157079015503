import axios, { AxiosRequestConfig } from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { configAxios, HOSTNAME } from '../shared';
import { OrgState } from './types';

const tmp = 0;

const actions: ActionTree<OrgState, RootState> = {
  mutateOrganistation({ commit }, payload: any) {
    commit('setOrgPrimaryColor', payload.primaryColor);
    commit('setOrgSecondaryColor', payload.secondaryColor);
    commit('setOrgLogo', payload.logo);
    commit('setOrgFavicon32', payload.favicon32);
    commit('setOrgFavicon16', payload.favicon16);
    commit('setOrgName', payload.name);
    commit('setOrgEmail', payload.email);
    commit('setOrgString', payload.orgString);
    commit('setOrgId', payload.orgId);
    commit('setOrgAGB', payload.agbUrl);
    commit('setOrgDatenschutz', payload.datenschutzUrl);
    commit('setOrgImpressum', payload.impressumUrl);
    commit('setOrgAcceptsRequests', payload.acceptsRequests);
    commit('setOrgAcceptsAppointments', payload.acceptsRequests);
  },
  async fetchOrgInfo({ commit, dispatch }, orgString: string): Promise<void> {
    commit('setUtilsAbsoluteLoading', true);

    const url = `${HOSTNAME}/enduser/org/${orgString}/`;
    const config: AxiosRequestConfig = await configAxios();
    // Fetch Organisation Info
    axios.get(url, config)
      .then((response) => {
        const payload = {
          orgString,
          ...response.data,
        };
        // console.log(payload);
        dispatch('mutateOrganistation', payload);
        commit('setUtilsAbsoluteLoading', false);
      })
      .catch((error) => {
        dispatch('utilsHandleAjaxError', error);
      });
  },
  async fetchOrganisationAvailability(
    { commit, dispatch, state },
    startDate: string,
  ): Promise<void> {
    commit('setUtilsLoadingAvailability', true);
    const url = `${HOSTNAME}/enduser/org/${state.orgString}/appointment?day=${startDate}`;
    const config: AxiosRequestConfig = await configAxios();
    // Fetch Organisation Info
    axios.get(url, config)
      .then((response) => {
        // // console.log(response.data);
        commit('setUtilsLoadingAvailability', false);
      })
      .catch((error) => {
        dispatch('utilsHandleAjaxError', error);
        commit('setUtilsLoadingAvailability', false);
      });
  },
};

export {
  actions,
  tmp,
};
