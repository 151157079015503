import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { OrgService, OrgServicesState } from './types';

export const tmp = 0;

export const getters: GetterTree<OrgServicesState, RootState> = {
  getOrgServiceAcceptsRequests(state): boolean {
    return state.acceptsRequests;
  },
  getOrgServiceAcceptsAppointments(state): boolean {
    return state.acceptsAppointments;
  },
  getOrgServicesLoading(state): boolean {
    return state.orgServicesError;
  },
  getOrgServicesError(state): boolean {
    return state.orgServicesError;
  },
  getOrgBookableServices(state): OrgService[] {
    return state.bookableServices;
  },
  getOrgServiceLaunchViewingAppointment(state): boolean {
    return state.launchViewingAppointment;
  },
  getOrgServiceLaunchRepairAppointment(state): boolean {
    return state.launchRepairAppointment;
  },
  getOrgServiceLaunchReport(state): boolean {
    return state.launchReport;
  },
  getOrdServiceShowMenu(state): boolean {
    return state.showMenu;
  },
};
