
import Vue from 'vue';
import NavBar from '@/components/UI/NavBar.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    NavBar,
  },
  beforeCreate() {
    window.location.replace('http://pdr.cloud');
  },
});
