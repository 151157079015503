import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/chat/getters';
import { mutations } from '@/store/chat/mutations';
import { actions } from '@/store/chat/actions';
import { ChatState } from '@/store/chat/types';

const state: ChatState = {
  user: '',
  enableChat: false,
  launchChat: false,
  chatroomId: '',
  messages: {},
  unreadMessagesIds: [],
  lastMessageRead: '',
};

export const tmp = 0;

export const chat: Module<ChatState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
