import axios, { AxiosRequestConfig } from 'axios';
import { ActionTree } from 'vuex';
import { configAxios, getUrl } from '../shared';
import { RootState } from '../types';
import { UserState } from './types';

const tmp = 0;

const actions: ActionTree<UserState, RootState> = {
  setUserInfos({ commit }, data: any) {
    commit('setUserName', data.name);
    commit('setUserPhone', data.phone);
    commit('setUserEmail', data.email);
    commit('setUserStreet', data.street);
    commit('setUserHouseNumber', data.houseNumber);
    commit('setUserExtraAddressLine', data.extraAddressLine);
    commit('setUserZip', data.zip);
    commit('setUserCity', data.city);
    commit('setUserCountry', data.country);
  },
  loginAnonymously({ commit }): void {
    commit('');
  },
  async sendUserInfo({ commit, state, getters }, payload): Promise<void> {
    const url = getUrl(payload);
    const config: AxiosRequestConfig = await configAxios();
    const auftrag = {
      customer: {
        title: state.title,
        name: state.name,
        phone: state.phone,
        email: state.email,
        street: state.street,
        houseNr: state.houseNumber,
        extraAddressLine: state.extraAddressLine,
        city: state.city,
        zip: state.zip,
        country: state.country,
      },
      vehicleKz: getters.getDamageReportVehicleKz,
      insurance: getters.getDamageReportVehicleInsuranceWithInsuranceType,
      notes: getters.getDamageReportNotes,
      damageNumber: getters.getDamageReportNumber,
    };
    // commit('setUtilsLoading', true);
    axios.post(url, auftrag, config)
      .then((response) => {
        // console.log(response);
        commit('setUtilsLoading', false);
      })
      .catch((error) => {
        // console.log(error);
        // commit('setUtilsLoading', true);
        commit('setUtilsLoading', false);
      });
  },
};

export {
  actions,
  tmp,
};
