import { MutationTree } from 'vuex';
import { UserState } from '@/store/user/types';

export const tmp = 0;

export const mutations: MutationTree<UserState> = {
  initUserState(state) {
    state.title = '';
    state.name = '';
    state.phone = '';
    state.email = '';
    state.street = '';
    state.houseNumber = '';
    state.extraAddressLine = '';
    state.zip = '';
    state.city = '';
    state.country = '';
    state.authorized = false;
  },
  setUserUid(state, value: string) {
    state.uid = value;
  },
  setUserAuthorized(state, value: boolean) {
    state.authorized = value;
  },
  setUserTitle(state, value: string) {
    state.title = value;
  },
  setUserName(state, value: string) {
    state.name = value;
  },
  setUserPhone(state, value: string) {
    state.phone = value;
  },
  setUserEmail(state, value: string) {
    state.email = value;
  },
  setUserStreet(state, value: string) {
    state.street = value;
  },
  setUserHouseNumber(state, value: string) {
    state.houseNumber = value;
  },
  setUserExtraAddressLine(state, value: string) {
    state.extraAddressLine = value;
  },
  setUserZip(state, value: string) {
    state.zip = value;
  },
  setUserCity(state, value: string) {
    state.city = value;
  },
  setUserCountry(state, value: string) {
    state.country = value;
  },
};
