import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { NavBarState } from './types';

export const tmp = 0;

export const getters: GetterTree<NavBarState, RootState> = {
  getNavBarShowBackButton(state): boolean {
    return state.showBackButton;
  },
  getNavBarShowMenu(state): boolean {
    return state.showMenu;
  },
};
