
import Vue from 'vue';
import ExampleImage from '@/components/UI/ExampleImage.vue';
import ImageUploaderProgressScreen from '@/components/ReportStepper/ImageUploaderProgressScreen.vue';

export default Vue.extend({
  name: 'OverviewImageUploader',
  data: () => ({
    imgSrcs: [
      'L_no.png',
      'M_ok.png',
      'S_no.png',
    ],
  }),
  components: {
    ExampleImage,
    ImageUploaderProgressScreen,
  },
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.getters.getUtilsImageUploadLoading;
      },
    },
    image: {
      get(): Record<string, any> {
        return this.$store.getters.getDamageReportOverviewImage;
      },
    },
    imageEmpty: {
      get(): boolean {
        return Object.keys(this.image).length === 0;
      },
    },
  },
  methods: {
    launchFileSelector() {
      const element: HTMLElement = document.getElementById('overview-input') as HTMLElement;
      element.click();
    },
    uploadFile(file: File) {
      const payload = {
        file,
        id: '',
        description: 'Schaden Übersichtbild',
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        attribute: 'image',
        mutation: 'setDamageReportOverviewImage',
      };
      this.$store.dispatch('uploadDamageReportImage', payload);
    },
    deleteImg() {
      this.$store.commit('deleteDamageReportOverviewImage');
    },
  },
});
