
import Vue from 'vue';
import WeekSlots from '@/components/AppointmentBooker/WeekSlots.vue';

export default Vue.extend({
  name: 'WeeklyCalendar',
  data: () => ({
    currentWeekIndex: 0,
  }),
  components: {
    WeekSlots,
  },
  computed: {
    isViewing: {
      get(): boolean {
        return this.$store.getters.getAppointmentIsViewing;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    appointment: {
      get(): string {
        if (this.isViewing) {
          return this.$store.getters.getViewingAppointment;
        }
        return this.$store.getters.getRepairAppointment;
      },
    },
    appointmentDateObject: {
      get(): Date {
        return new Date(Date.parse(this.appointment));
      },
    },
    currentWeek: {
      get(): string {
        const monday = new Date();
        monday.setDate(monday.getDate() - monday.getDay() + 1 + 7 * this.currentWeekIndex);
        const sunday: Date = new Date(monday);
        sunday.setDate(sunday.getDate() + 6);
        return `${monday.toLocaleDateString()}  -  ${sunday.toLocaleDateString()}`;
      },
    },
    firstWeekSlots: {
      get(): any {
        return this.$store.getters.getAppointmentsFirstWeek;
      },
    },
    secondWeekSlots: {
      get(): any {
        return this.$store.getters.getAppointmentsSecondWeek;
      },
    },
    thirdWeekSlots: {
      get(): any {
        return this.$store.getters.getAppointmentsThirdWeek;
      },
    },
    fourthWeekSlots: {
      get(): any {
        return this.$store.getters.getAppointmentsFourthWeek;
      },
    },
    firstWeekLoading: {
      get(): boolean {
        return this.$store.getters.getAppointmentsWeekLoading(0);
      },
    },
    secondWeekLoading: {
      get(): boolean {
        return this.$store.getters.getAppointmentsWeekLoading(1);
      },
    },
    thirdWeekLoading: {
      get(): boolean {
        return this.$store.getters.getAppointmentsWeekLoading(2);
      },
    },
    fourthWeekLoading: {
      get(): boolean {
        return this.$store.getters.getAppointmentsWeekLoading(3);
      },
    },
    weekLoading: {
      get(): any[] {
        return [
          this.firstWeekLoading,
          this.secondWeekLoading,
          this.thirdWeekLoading,
          this.fourthWeekLoading,
        ];
      },
    },
    slots: {
      get(): any[] {
        return [
          this.firstWeekSlots,
          this.secondWeekSlots,
          this.thirdWeekSlots,
          this.fourthWeekSlots,
        ];
      },
    },
  },
  methods: {
    notify(slots: any) {
      this.$emit('notify', slots);
    },
    nextWeek() {
      if (this.currentWeekIndex < 3) {
        this.currentWeekIndex += 1;
      }
    },
    previousWeek() {
      if (this.currentWeekIndex > 0) {
        this.currentWeekIndex -= 1;
      }
    },
    formatDate(date: Date) {
      // return `${date?.getDate()}.${date?.getMonth() + 1}.${date?.getFullYear()}`;
      return date.toLocaleDateString();
    },
    selectedColor(date: Date) {
      if (this.formatDate(date) === this.formatDate(this.appointmentDateObject)) {
        return `border-color: ${this.primaryColor}; border-width: 3px`;
      }
      return 'border-color: #CCCCCC';
    },
  },
});
