
import Vue from 'vue';
import ImageUploaderProgressScreen from '@/components/ReportStepper/ImageUploaderProgressScreen.vue';
import ExampleImage from '@/components/UI/ExampleImage.vue';

export default Vue.extend({
  name: 'NearImagesUploader',
  data: () => ({
    img: '',
    overlay: false,
    imgSrcs: [
      'L_no.png',
      'M_no.png',
      'S_ok.png',
    ],
  }),
  components: {
    ImageUploaderProgressScreen,
    ExampleImage,
  },
  computed: {
    images: {
      get(): Record<string, any> {
        return this.$store.getters.getDamageReportNearImages;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.getters.getUtilsImageUploadLoading;
      },
    },
  },
  methods: {
    launchFileSelector() {
      const element: HTMLElement = document.getElementById('near-input') as HTMLElement;
      element.click();
    },
    uploadFile(file: File) {
      const payload = {
        file,
        description: 'Nahaufnahmen',
        id: Math.random().toString(),
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        attribute: 'image',
        mutation: 'setDamageReportNearImage',
      };
      this.$store.dispatch('uploadDamageReportImage', payload);
    },
    displayImg(img: string) {
      this.img = img;
      this.overlay = true;
    },
    hideImg() {
      this.overlay = false;
    },
    deleteFile(image: Record<string, any>) {
      const payload = {
        filename: image.filename,
        id: image.id,
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        attribute: 'image',
        mutation: 'deleteDamageReportNearImage',
      };
      this.$store.dispatch('deleteDamageReportImage', payload);
    },
  },
});
