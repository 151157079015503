import { AxiosRequestConfig } from 'axios';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '@/firebase';
import { IReCaptchaLoaderOptions } from 'recaptcha-v3/dist/ReCaptchaLoader';
import { load } from 'recaptcha-v3';

// const HOSTNAME = 'https://europe-west3-reparaturinfo-dev.cloudfunctions.net/OUTside';
// const HOSTNAME = 'http://localhost:5000';
// const HOSTNAME = 'http://127.0.0.1:5000';
// const HOSTNAME = 'https://europe-west3-reparaturinfo-prod.cloudfunctions.net/OUTside';
const HOSTNAME = 'https://europe-west3-reparaturinfo-prod.cloudfunctions.net/OUTside2';

/* eslint-disable-next-line max-len */
export const HOSTNAME_AR_PATTERN = /^https:\/\/europe-west3-reparaturinfo-prod.cloudfunctions.net\/OUTside\/enduser\/org\/\w*\/request\/$/;
/* eslint-disable-next-line max-len */
// export const HOSTNAME_AR_PATTERN = /^https:\/\/europe-west3-reparaturinfo-dev.cloudfunctions.net\/OUTside\/enduser\/org\/\w*\/request\/$/;

async function configAxios(accessToken?: string): Promise<AxiosRequestConfig> {
  // get JWT
  let jwt: string | undefined = await auth.currentUser?.getIdToken();
  if (jwt === undefined) {
    await signInAnonymously(auth);
    jwt = await auth.currentUser?.getIdToken();
  }
  let config: AxiosRequestConfig;
  if (accessToken !== undefined) {
    // // console.log(accessToken);
    config = {
      headers: {
        Authorization: `Bearer ${jwt}`,
        AccessToken: accessToken,
      },
    };
  } else {
    config = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
  }

  return config;
}

function getUrl(params: any): string {
  if (params.token === undefined || params.token === '') {
    return `${HOSTNAME}/enduser/org/${params.orgString}/request/`;
  }
  return `${HOSTNAME}/enduser/org/${params.orgString}/auftrag/${params.token}/`;
}

function setDocTitleAndFavicon(title?: string, icon?: string, iconSize?: number): void {
  if (icon !== undefined) {
    const favicon = <HTMLLinkElement>document.querySelector(`link[rel~='icon'][sizes='${iconSize}x${iconSize}']`);
    favicon.href = icon;
  }
  if (title !== undefined) {
    document.title = title;
  }
}

function getMonday(index: number): string {
  if (index > 3 || index < 0) {
    // console.log(`index ${index}`);
    throw new Error('index must be 0, 1, 2 or 3');
  }
  const day: Date = new Date();
  day.setDate(day.getDate() - day.getDay() + 1 + 7 * index);
  return day.toISOString().split('T')[0];
}

// dev
// const SITE_KEY = '6LegB1seAAAAAJS7OM2a-mdeEhwVwgBfvVMquKHY';

// prod
const SITE_KEY = '6LeJFnUeAAAAABCuVqYLmkL6CQxEz2P0OlKlZUgV';

const RECAPTCHA_OPTIONS: IReCaptchaLoaderOptions = {
  useEnterprise: true,
  autoHideBadge: true,
};

async function getRecaptchaToken(action: string): Promise<string> {
  const recaptcha = await load(SITE_KEY, RECAPTCHA_OPTIONS);
  const token = await recaptcha.execute(action);
  // console.log(token);
  return token;
}

export {
  configAxios,
  setDocTitleAndFavicon,
  getUrl,
  getMonday,
  HOSTNAME,
  SITE_KEY,
  RECAPTCHA_OPTIONS,
  getRecaptchaToken,
};
