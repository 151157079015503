import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '@/firebase';
import Home from '@/views/Home.vue';
import OrgHomePage from '@/views/OrgHomePage.vue';
import DamageReport from '@/views/DamageReport.vue';
import ViewingAppointment from '@/views/ViewingAppointment.vue';
import RepairAppointment from '@/views/RepairAppointment.vue';
import AuftragPage from '@/views/AuftragPage.vue';
import ChatPage from '@/views/ChatPage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
  {
    path: '/:orgString',
    name: 'OrgPage',
    component: OrgHomePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:orgString/chat',
    name: 'Chat',
    component: ChatPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:orgString/schadenaufnahme',
    name: 'DamageReport',
    component: DamageReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:orgString/besichtigung',
    name: 'ViewingAppointment',
    component: ViewingAppointment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:orgString/:token',
    name: 'AuftragPage',
    component: AuftragPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:orgString/:token/schadenaufnahme',
    name: 'AuftragDamageReport',
    component: DamageReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:orgString/:token/besichtigung',
    name: 'AuftragViewingAppointment',
    component: ViewingAppointment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:orgString/:token/reparatur',
    name: 'AuftragRepairAppointment',
    component: RepairAppointment,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Sign in user anonymously
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => (x.meta.requiresAuth));
  if (requiresAuth && !auth.currentUser) {
    signInAnonymously(auth)
      .then((value) => {
        console.log(`signed in anonymously as ${value.user.uid}`);
        next();
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  next();
});

export default router;
