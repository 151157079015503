
import Vue from 'vue';
import ExampleImage from '@/components/UI/ExampleImage.vue';
import ImageUploaderProgressScreen from '@/components/ReportStepper/ImageUploaderProgressScreen.vue';

export default Vue.extend({
  name: 'VehicleDashboardUploader',
  data: () => ({
    imgSrcs: [
      'milage.jpg',
    ],
  }),
  components: {
    ExampleImage,
    ImageUploaderProgressScreen,
  },
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.getters.getUtilsImageUploadLoading;
      },
    },
    image: {
      get(): Record<string, any> {
        return this.$store.getters.getDamageReportVehicleDashboard;
      },
    },
    imageEmpty: {
      get(): boolean {
        return Object.keys(this.image).length === 0;
      },
    },
  },
  methods: {
    launchFileSelector() {
      const element: HTMLElement = document.getElementById('dashboard-input') as HTMLElement;
      element.click();
    },
    uploadFile(file: File) {
      const payload = {
        file,
        id: '',
        description: 'Kilometerstand',
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        attribute: 'vehicleDashboard',
        mutation: 'setDamageReportVehicleDashboard',
      };
      this.$store.dispatch('uploadDamageReportImage', payload);
    },
    deleteFile() {
      const payload = {
        filename: 'vehicleDashboard',
        id: '',
        orgString: this.$route.params.orgString,
        token: this.$route.params.token,
        attribute: 'vehicleDashboard',
        mutation: 'deleteDamageReportVehicleDashboard',
      };
      this.$store.dispatch('deleteDamageReportImage', payload);
    },
  },
});
