
import Vue from 'vue';

export default Vue.extend({
  name: 'WeekSlots',
  props: {
    weekIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isViewing: {
      get(): boolean {
        return this.$store.getters.getAppointmentIsViewing;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    week: {
      get(): any {
        return this.$store.getters.getAppointmentsWeek(this.weekIndex);
      },
    },
    weekIsLoading: {
      get(): boolean {
        return this.$store.getters.getAppointmentsWeekLoading(this.weekIndex);
      },
    },
    appointment: {
      get(): string {
        if (this.isViewing) {
          return this.$store.getters.getViewingAppointment;
        }
        return this.$store.getters.getRepairAppointment;
      },
    },
    appointmentDateObject: {
      get(): Date {
        return new Date(Date.parse(this.appointment));
      },
    },
    isWeekEmpty: {
      get(): boolean {
        const noDays = this.week === undefined || Object.keys(this.week).length === 0 || this.week === 'empty';
        if (noDays) {
          return false;
        }
        return Object.values(this.week).every((day: any) => day.length === 0);
      },
    },
  },
  methods: {
    notify(slots: string[], date: any) {
      this.$emit('notify', { slots, date });
    },
    selectedColor(date: any): string {
      if (date === this.appointmentDateObject.toLocaleDateString().split('T')[0]) {
        return `border-color: ${this.primaryColor}; border-width: 3px`;
      }
      return 'border-color: #CCCCCC';
    },
    formatDay(day: any): string {
      const date: Date = new Date(day);
      return date.toLocaleDateString();
    },
    getDayName(day: any): string {
      const date = new Date(day);
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      return date.toLocaleDateString('de-DE', options).split(',')[0];
    },
    checkDate(date: any) {
      const today: Date = new Date();
      today.setHours(0, 0, 0, 0);
      const day: Date = new Date(date);
      return today < day && this.week[date].length > 0;
    },
  },
  created() {
    if (this.weekIndex > 4) {
      return;
    }
    if (this.week === '') {
      this.$store.dispatch('fetchWeekAvailability', this.weekIndex);
    }
    if (this.weekIndex < 3) {
      this.$store.dispatch('fetchWeekAvailability', this.weekIndex + 1);
    }
  },
});
