import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import {
  Database, getDatabase, ref, get, push, set, onChildAdded, query, limitToLast,
  startAt, endAt, onValue, onDisconnect, update, onChildChanged,
} from 'firebase/database';
import {
  getStorage, FirebaseStorage, uploadBytes, getDownloadURL, uploadString, ref as storageRef,
} from 'firebase/storage';

// firebase init - add your own config here
// const firebaseConfig = {
//   apiKey: 'AIzaSyCz1hD0HcZYWJOeokNmXOVrQW0ZWSCcxtA',
//   authDomain: 'reparaturinfo-dev.firebaseapp.com',
//   databaseURL: 'https://reparaturinfo-dev-default-rtdb.europe-west1.firebasedatabase.app',
//   projectId: 'reparaturinfo-dev',
//   storageBucket: 'reparaturinfo-dev.appspot.com',
//   messagingSenderId: '908066522953',
//   appId: '1:908066522953:web:d19008035591e79b273c98',
// };

const firebaseConfig = {
  apiKey: 'AIzaSyBhV3KYscen4x4GMgZ18fNaG4xsnKBmRPQ',
  authDomain: 'reparaturinfo-prod.firebaseapp.com',
  databaseURL: 'https://reparaturinfo-prod-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'reparaturinfo-prod',
  storageBucket: 'reparaturinfo-prod.appspot.com',
  messagingSenderId: '322811853497',
  appId: '1:322811853497:web:0717eca78b864f70ff7968',
  measurementId: 'G-DR1KQHFWNY',
};
const app: FirebaseApp = initializeApp(firebaseConfig);

// utils
const auth: Auth = getAuth(app);
const database: Database = getDatabase(app);
const storage: FirebaseStorage = getStorage(app);

// export utils/refs
export {
  auth,
  app,
  database,
  ref,
  get,
  set,
  update,
  push,
  onChildAdded,
  onChildChanged,
  query,
  limitToLast,
  startAt,
  endAt,
  onValue,
  onDisconnect,
  storage,
  uploadString,
  uploadBytes,
  storageRef,
};
