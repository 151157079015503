import axios, { AxiosRequestConfig } from 'axios';
import { ActionTree } from 'vuex';
import { configAxios, HOSTNAME } from '../shared';
import { RootState } from '../types';
import { DocumentState } from './types';

export const tmp = 0;

export const actions: ActionTree<DocumentState, RootState> = {
  async sendSignedDocument({ commit, state }, payload: any) {
    const config: AxiosRequestConfig = await configAxios(payload.accessToken);
    const url = `${HOSTNAME}/enduser/org/${payload.orgString}/auftrag/${payload.token}/`;
    const auftrag = {
      doc: state.documents[payload.index],
    };
    axios.post(url, auftrag, config)
      .then((response) => {
        // console.log(response);
        // hide loading screen
        commit('setUtilsDocSignUploadLoading', true);
      })
      .catch((error) => {
        // dispatch('utilsHandleAjaxError', error);
        // console.log(error);
      });
  },
};
