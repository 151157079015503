
import Vue from 'vue';

export default Vue.extend({
  name: 'TheMenu',
  computed: {
    orgString: {
      get(): string {
        return this.$route.params.orgString;
      },
    },
    signDocs: {
      get(): boolean {
        return this.$store.getters.getAuftragSignDocs;
      },
    },
    acceptsRequests: {
      get(): boolean {
        return this.$store.getters.getOrgAcceptsRequests;
      },
    },
    acceptsAppointments: {
      get(): boolean {
        return this.$store.getters.getOrgAcceptsAppointments;
      },
    },
    email: {
      get(): string {
        return this.$store.getters.getOrgEmail;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    agb: {
      get(): string {
        return this.$store.getters.getOrgAGB;
      },
    },
    datenschutz: {
      get(): string {
        return this.$store.getters.getOrgDatenschutz;
      },
    },
    impressum: {
      get(): string {
        return this.$store.getters.getOrgImpressum;
      },
    },
    isComplete: {
      get(): boolean {
        return this.$store.getters.getAuftragIsComplete;
      },
    },
    showReport: {
      get(): boolean {
        if (this.$route.params.token !== undefined) {
          return this.$store.getters.getAuftragMakeReport;
        }
        return this.acceptsRequests;
      },
    },
    showViewingAppointment: {
      get(): boolean {
        if (this.$route.params.token !== undefined) {
          return this.$store.getters.getAuftragMakeViewingAppointment;
        }
        return this.acceptsAppointments;
      },
    },
    showRepairAppointment: {
      get(): boolean {
        return this.$store.getters.getAuftragMakeRepairAppointment;
      },
    },
  },
  methods: {
    exit() {
      this.$emit('notify');
    },
    launchReport() {
      this.$router.push(`/${this.orgString}/schadenaufnahme`);
    },
    launchViewingAppointment() {
      this.$router.push(`/${this.orgString}/besichtigung`);
    },
    launchRepairAppointment() {
      this.$router.push(`/${this.orgString}/reparatur`);
    },
    sendEmail() {
      const element: HTMLElement = document.getElementById('email') as HTMLElement;
      element.click();
    },
  },
});
