import { render, staticRenderFns } from "./ExtraImagesUploader.vue?vue&type=template&id=218513c7&scoped=true&"
import script from "./ExtraImagesUploader.vue?vue&type=script&lang=ts&"
export * from "./ExtraImagesUploader.vue?vue&type=script&lang=ts&"
import style0 from "./ExtraImagesUploader.vue?vue&type=style&index=0&id=218513c7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "218513c7",
  null
  
)

export default component.exports