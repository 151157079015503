import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/documents/getters';
import { mutations } from '@/store/documents/mutations';
import { actions } from '@/store/documents/actions';
import { DocumentState } from '@/store/documents/types';

const state: DocumentState = {
  documents: [],
  currentStep: 0,
  totalSteps: -1,
};

export const tmp = 0;

export const documents: Module<DocumentState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
