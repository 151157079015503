import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { ErrorType, UtilsState } from './types';

export const tmp = 0;

export const getters: GetterTree<UtilsState, RootState> = {
  getDamageReportCheckVehicleDrivable(state): boolean {
    return state.checkVehicleDrivable;
  },
  getUtilsLoading(state): boolean {
    return state.loading;
  },
  getUtilsLoadingAvailability(state): boolean {
    return state.loadingAvailability;
  },
  getUtilsAbsoluteLoading(state): boolean {
    return state.absoluteLoading;
  },
  getUtilsCriticalError(state): boolean {
    return state.criticalError;
  },
  getUtilsCriticalErrorType(state): ErrorType|undefined {
    return state.criticalErrorType;
  },
  getUtilsCriticalErrorMessage(state): string|undefined {
    return state.criticalErrorMessage;
  },
  getUtilsNonCriticalError(state): boolean {
    return state.nonCriticalError;
  },
  getUtilsNonCriticalErrorType(state): ErrorType|undefined {
    return state.nonCriticalErrorType;
  },
  getUtilsNonCriticalErrorMessage(state): string|undefined {
    return state.criticalErrorMessage;
  },
  getUtilsResponseStatusCode(state): number|undefined {
    return state.responseStatusCode;
  },
  getUtilsImageUploadLoading(state): boolean {
    return state.imageUploadLoading;
  },
  getUtilsDocSignUploadLoading(state): boolean {
    return state.docSignUploadLoading;
  },
  getStepperCurrentStep(state): number {
    return state.stepperCurrentStep;
  },
  getStepperTotalSteps(state): number {
    return state.stepperTotalSteps;
  },
  getUtilsShowSnackbar(state): boolean {
    return state.showSnackbar;
  },
  getUtilsSnackbarMessage(state): string {
    return state.snackbarMessage;
  },
  getUtilsConnectionLost(state): boolean|undefined {
    return state.connectionLost;
  },
};
