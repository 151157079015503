
import Vue from 'vue';

export default Vue.extend({
  name: 'BottomNavigation',
  props: {
    buttonText: {
      type: String,
      default: 'Nächster Schritt',
    },
  },
  methods: {
    goNext() {
      this.$store.dispatch('clickNextBtn', this.$route.name);
      // this.$emit('notify');
    },
    goBack() {
      this.$store.dispatch('clickBackBtn', this.$route.name);
    },
  },
});
