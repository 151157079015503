import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence, { AsyncStorage } from 'vuex-persist';
import localforage from 'localforage';
import { RootState } from './types';
import { organisation } from './organisation/index';
import { auftrag } from './auftrag/index';
import { organisationServices } from './services/index';
import { user } from './user/index';
import { utils } from './utils/index';
import { documents } from './documents/index';
import { damageReport } from './damageReport/index';
import { appointmentSlots } from './appointment/index';
import { chat } from './chat';
import { navbar } from './navbar';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersistence<RootState>({
  storage: localStorage,
  modules: [
    'organisation', 'user',
  ],
});

localforage.config({
  name: 'damageReport',
});

const vuexLocalForage = new VuexPersistence<RootState>({
  asyncStorage: true,
  storage: localforage as AsyncStorage,
  modules: [
    'damageReport',
  ],
});

const store: StoreOptions<RootState> = {
  state: {
    setCarCondition: true,
    launchReport: false,
    launchAppointment: false,
    showServiceCards: true,
    showBackButton: false,
    restartOrContinue: false,
    storeLocaly: false,
  },
  getters: {
    getShowBackButton(state): boolean {
      return state.showBackButton;
    },
  },
  mutations: {
    setSetCarCondition(state, value) {
      state.setCarCondition = value;
    },
    setLaunchreport(state, value) {
      state.launchReport = value;
    },
    setLaunchAppointment(state, value) {
      state.launchAppointment = value;
    },
    setShowServiceCards(state, value) {
      state.showServiceCards = value;
    },
    setShowBackButton(state, value) {
      state.showBackButton = value;
    },
    setRestartOrContinue(state, value) {
      state.restartOrContinue = value;
    },
    setStoreLocaly(state, value) {
      state.storeLocaly = value;
    },
  },
  modules: {
    organisation,
    auftrag,
    organisationServices,
    user,
    utils,
    documents,
    damageReport,
    appointmentSlots,
    chat,
    navbar,
  },
  plugins: [
    vuexLocalStorage.plugin,
    vuexLocalForage.plugin,
  ],
  devtools: false,
};

export default new Vuex.Store(store);
