import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { OrgState } from './types';

export const tmp = 0;

export const getters: GetterTree<OrgState, RootState> = {
  getOrgName(state): string | undefined {
    return state.name;
  },
  getOrgString(state): string | undefined {
    return state.orgString;
  },
  getOrgId(state): string | undefined {
    return state.orgId;
  },
  getOrgLogo(state): string | undefined {
    return state.logo;
  },
  getOrgFavicon32(state): string | undefined {
    return state.favicon32;
  },
  getOrgFavicon16(state): string | undefined {
    return state.favicon16;
  },
  getOrgPrimaryColor(state): string {
    return state.primaryColor;
  },
  getOrgSecondaryColor(state): string {
    return state.secondaryColor;
  },
  getOrgEmail(state): string | undefined {
    return state.email;
  },
  getOrgLoading(state): boolean {
    return state.orgLoading;
  },
  getOrgError(state): boolean {
    return state.orgError;
  },
  getOrgAGB(state): string | undefined {
    return state.agb_url;
  },
  getOrgDatenschutz(state): string | undefined {
    return state.datenschutz_url;
  },
  getOrgImpressum(state): string | undefined {
    return state.impressum_url;
  },
  getOrgAcceptsRequests(state): boolean {
    return state.acceptsRequests;
  },
  getOrgAcceptsAppointments(state): boolean {
    return state.acceptsAppointments;
  },
};
