import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/organisation/getters';
import { mutations } from '@/store/organisation/mutations';
import { actions } from '@/store/organisation/actions';
import { OrgState } from '@/store/organisation/types';

const state: OrgState = {
  orgLoading: false,
  orgError: false,
  logo: '',
  primaryColor: '#3F51B5',
  secondaryColor: '#FF7F50',
  acceptsRequests: false,
  acceptsAppointments: false,
};

export const tmp = 0;

export const organisation: Module<OrgState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
