import Vue from 'vue';
import { MutationTree } from 'vuex';
import { DamageReportState } from './types';

const tmp = 0;

const mutations: MutationTree<DamageReportState> = {
  initDamageReport(state) {
    state.vehicleKz = '';
    state.kzCountry = 'germany';
    state.vehicleDashboard = {};
    state.notes = '';
    state.insurance = '';
    state.damageNumber = '';
    state.vehicleRegistration = {
      front: {},
      back: {},
    };
    state.farImages = {
      'front-left': {},
      'back-right': {},
    };
    state.nearImages = {};
    state.extraImages = {};
    state.overviewImage = {};
    state.isFinished = false;
  },
  setDamageReportVehicleKz(state, vehicleKz: string) {
    state.vehicleKz = vehicleKz;
  },
  setDamageReportVehicleKzCountry(state, country: 'germany' | 'other') {
    state.kzCountry = country;
  },
  setDamageReportInsurance(state, value: string) {
    state.insurance = value;
  },
  setDamageReportInsuranceType(state, value: 'Haftpflicht' | 'Vollkasko' | 'Teilkasko') {
    state.insuranceType = value;
  },
  setDamageReportNotes(state, value: string) {
    state.notes = value;
  },
  setDamageReportNumber(state, value: string) {
    state.damageNumber = value;
  },
  setDamageReportFarImage(state, value: Record<string, any>) {
    Vue.set(state.farImages, value.id, value.data);
  },
  deleteDamageReportFarImage(state, id: string) {
    Vue.set(state.farImages, id, {});
  },
  setDamageReportNearImage(state, value: Record<string, any>) {
    Vue.set(state.nearImages, value.id, value.data);
  },
  deleteDamageReportNearImage(state, id: string) {
    Vue.delete(state.nearImages, id);
  },
  setDamageReportExtraImage(state, value: Record<string, any>) {
    Vue.set(state.extraImages, value.id, value.data);
  },
  deleteDamageReportExtraImage(state, id: string) {
    Vue.delete(state.extraImages, id);
  },
  setDamageReportVehicleRegistration(state, value: Record<string, any>) {
    Vue.set(state.vehicleRegistration, value.id, value.data);
  },
  deleteDamageVehicleRegistration(state, id: string) {
    Vue.set(state.vehicleRegistration, id, {});
  },
  setDamageReportVehicleDashboard(state, value: Record<string, any>) {
    Vue.set(state, 'vehicleDashboard', value.data);
  },
  deleteDamageReportVehicleDashboard(state) {
    Vue.set(state, 'vehicleDashboard', {});
  },
  setDamageReportOverviewImage(state, value: Record<string, any>) {
    Vue.set(state, 'overviewImage', value.data);
  },
  deleteDamageReportOverviewImage(state) {
    Vue.set(state, 'overviewImage', {});
  },
  setDamageReportIsFinished(state, value: boolean) {
    state.isFinished = value;
  },
  setDamageReportCurrentStep(state, value: number) {
    state.currentStep = value;
  },
  setDamageReportTotalSteps(state, value: number) {
    state.totalSteps = value;
  },
};

export {
  mutations,
  tmp,
};
