
import Vue from 'vue';

export default Vue.extend({
  name: 'ReviewScreenAppointment',
  computed: {
    isViewing: {
      get(): boolean {
        return this.$store.getters.getAppointmentIsViewing;
      },
    },
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    day: {
      get(): string {
        const date: Date = new Date(Date.parse(this.appointment));
        return date.toLocaleDateString();
      },
    },
    time: {
      get(): string {
        const date: Date = new Date(Date.parse(this.appointment));
        return `${date.toLocaleTimeString().split(':')[0].replace(/^0/, '')}:${date.toLocaleTimeString().split(':')[1]}`;
      },
    },
    appointment: {
      get(): string {
        if (this.isViewing) {
          return this.viewingAppointment;
        }
        return this.repairAppointment;
      },
    },
    viewingAppointment: {
      get(): string {
        return this.$store.getters.getViewingAppointment;
      },
    },
    repairAppointment: {
      get(): string {
        return this.$store.getters.getRepairAppointment;
      },
    },
  },
});
