
import Vue from 'vue';

export default Vue.extend({
  name: 'ErrorScreen',
  props: {
    title: {
      type: String,
      default: '404 Not Found',
    },
    subTitle: {
      type: String,
      default: 'Die angeforderte Ressource wurde nicht gefunden.',
    },
  },
  mounted() {
    this.$store.commit('setUtilsLoading', false);
    this.$store.commit('setUtilsAbsoluteLoading', false);
  },
});
