
import Vue from 'vue';

export default Vue.extend({
  name: 'ImageUploaderProgressScreen',
  data: () => ({
    loadingMsg: 'Lädt hoch ...',
    loadedMsg: 'Erfolgreich hochgeladen',
  }),
  components: {},
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.state.imageUploading;
      },
    },
  },
});
