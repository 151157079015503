
import Vue from 'vue';
import { DatePickerFormatter } from 'vuetify';

export default Vue.extend({
  name: 'AppointmentBookerTimePicker',
  props: {
    times: {
      type: Array,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    primaryColor: {
      get(): string {
        return this.$store.getters.getOrgPrimaryColor;
      },
    },
    formatedDate: {
      get(): string {
        const day = new Date(this.date);
        return day.toLocaleDateString();
      },
    },
    weekDay: {
      get(): string {
        const day = new Date(this.date);
        const options: Intl.DateTimeFormatOptions = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        return day.toLocaleDateString('de-DE', options).split(',')[0];
      },
    },
  },
  methods: {
    notify(time: any) {
      this.$emit('notify', time);
    },
    selectedColor(time: any): string {
      const localDate: Date = new Date(this.date);
      if (`${localDate.toTimeString().split(':')[0]}:${localDate.toTimeString().split(':')[1]}` === time) {
        return `border-color: ${this.primaryColor}; border-width: 3px`;
      }
      return 'border-color: #CCCCCC';
    },
    formatTime(time: any): string {
      const date: Date = new Date(Date.parse(time));
      return `${date.toLocaleTimeString().split(':')[0].replace(/^0/, '')}:${date.toLocaleTimeString().split(':')[1]}`;
    },
  },
});
